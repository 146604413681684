<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8" v-for="item in caseList" :key="item.id">
        <el-card shadow="hover" :body-style="{ padding: '0px' }" class="card">
          <div slot="header" class="clearfix">
            <span>东兴口岸北仑河二桥验货场</span>
          </div>
          <div class="card_content">
            <img
              @click="handleClick(item.id)"
              src="https://axure-file.lanhuapp.com/md5__7d60d3f628d462158ee8254fa9fa9e56.png"
              class="image"
            />
          </div>

          <div style="padding: 14px">
            <span>海关特殊商品指定监管口岸</span>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      caseList: [
        {
          id: 1,
          title: "东兴口岸北仑河二桥验货场",
        },
      ],
    };
  },
  methods: {
    handleClick(id) {
      this.$router.push({
        path: "/businessServe/case",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  cursor: pointer;
  .card_content {
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px 0;
    img {
      width: 90%;
      height: 100%;
    }
  }
}
</style>
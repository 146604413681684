<!--
 * @Author: your name
 * @Date: 2021-07-01 09:17:23
 * @LastEditTime: 2021-07-02 10:17:42
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\components\contentTitle.vue
-->
<template>
  <div class="content_title">
    <div>
      <router-link :to="'/index'">首页</router-link>
      >
      <span v-if="link.meta.father">
        <router-link :to="link.meta.link">{{ link.meta.father }}</router-link>
        >
      </span>
      <router-link :to="link.path"
        class="active">{{
        link.meta.title
      }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      link: "",
    };
  },
  computed: {
    myRoute() {
      return this.$route;
    },
  },
  created() {
    this.link = this.myRoute;
  },
};
</script>

<style lang="scss" scoped>
.content_title {
  margin: 24px 0;
  font-size: 12px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #333333;
  a {
    display: inline;
  }
  a:hover {
    color: #d90000;
  }
  .active {
    color: #d90000;
  }
}
</style>
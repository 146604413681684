<template>
  <div class="inner_container">
    <div class="container">
      <!-- <title-images :imgObj="myImageObj" /> -->
      <!-- <img class="header_banner"
        :src="activeIndex == 'g_1'?aboutBanner1
        :activeIndex == 'g_2'?aboutBanner2
        :activeIndex == 'g_3'?aboutBanner3
        :activeIndex == 'g_4'?aboutBanner4
        :activeIndex == 'g_5'?aboutBanner5
        :activeIndex == 'g_6'?aboutBanner6:aboutBanner7"
        alt=""> -->
      <el-carousel :interval="5000" arrow="always">
        <div class="swiper_content" id="swiper_about">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" width="100%" height="100%" />
          </el-carousel-item>
        </div>
      </el-carousel>
      <div class="backgroud_img">
        <div class="inner_content">
          <!-- <content-tltle /> -->

          <div class="text_box">
            <div class="left_box left_about_notice">
              <p class="about_title">{{ $t("home.draw_about") }}</p>
              <ul>
                <li @click="showTabNav(1)">
                  <span></span>
                  <div>{{ $t("home.enterprise_overview") }}</div>
                </li>
                <div class="business_genner" v-show="isShowGenner">
                  <ul>
                    <li
                      v-for="(item, index) in gennerNavs"
                      @click="tabChangeBtn(item)"
                      :key="index"
                      :class="{
                        activeColor_five: item.id === activeIndex,
                      }"
                    >
                      <span></span> {{ item.name }}
                    </li>
                  </ul>
                </div>

                <li @click="showTabNav(2)">
                  <span></span>
                  <div>{{ $t("home.branch") }}</div>
                </li>
                <div class="business_genner" v-show="isShowbrief">
                  <ul>
                    <li
                      v-for="(item, index) in briefNavs"
                      @click="tabChangeBtn(item)"
                      :key="index"
                      :class="{
                        activeColor_five: item.id === activeIndex,
                      }"
                    >
                      <span></span> {{ item.name }}
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div class="right_box">
              <!-- <p class="about_title">{{titleName}}</p> -->
              <div class="item_info item_mcjj" v-show="activeIndex == 'g_1'">
                <div class="contant_item">
                  <div class="micang_jj">
                    {{ $t("home.rice_introduction") }}
                  </div>
                  <div class="yinwen_ms">GOODCHAINS INTRODUCTION</div>
                  <div class="item_title">{{ $t("home.micang_group") }}</div>
                  <div class="info_des">
                    {{ $t("home.micang_group_text_1") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.micang_group_text_2") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.micang_group_text_3") }}
                  </div>
                </div>
              </div>
              <div class="item_info item_qyyj" v-show="activeIndex == 'g_2'">
                <div class="contant_item company_wh">
                  <div class="wh_left left_line">
                    <div>{{ $t("home.corporate_culture") }}</div>
                    <div class="wenhua_yw">COMPANY CULTURE</div>
                    <div>
                      <span class="span_line span_first">{{
                        $t("home.intellectual_management")
                      }}</span
                      ><span class="span_line">{{
                        $t("home.meticulous_service")
                      }}</span
                      ><span class="span_line span_last">{{
                        $t("home.grow_together")
                      }}</span>
                    </div>
                  </div>
                  <div class="wh_left" style="margin-top: 60px">
                    <div>{{ $t("home.gorporate_vision") }}</div>
                    <div class="wenhua_yw">CORPORATE VISION</div>
                    <div>
                      <span>{{ $t("home.margin_mc_world_win") }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item_info" v-show="activeIndex == 'g_3'">
                <div class="contant_item contant_item_zr">
                  <div class="item_title item_title_zr">
                    {{ $t("home.news_title_dynamic") }}
                  </div>
                  <div class="item_title_zr f_title">
                    {{ $t("home.help_each_other") }}
                  </div>
                  <div>
                    <span style="margin-right: 28px">2020-01-27</span
                    ><span>{{ $t("home.view_count") }}：978516</span>
                  </div>
                  <div class="info_des info_des_two">
                    <ul>
                      <li>{{ $t("home.help_each_other_text") }}</li>
                      <li>{{ $t("home.help_each_other_text_2") }}</li>
                    </ul>
                  </div>
                </div>
                <img
                  class="zr_img"
                  src="../../../assets/about_notice/社会与责任.jpg"
                  alt=""
                />
              </div>
              <div class="item_info item_kgmc" v-show="activeIndex == 'g_4'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="about_gsmc">{{ $t("home.gz_airport_rice") }}</div>
                  <div class="yinwen_ms">
                    GUANGZHOU AEROTROPOLIS GOODCHAINS GROUP CO.,LTD
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.gz_airport_text_1") }}
                  </div>
                  <!-- </a> -->
                  <a
                    class="target_shopping"
                    target="_blank"
                    href="https://en.aerochains.com"
                    >{{ $t("home.mall_entrance") }} ></a
                  >
                </div>
              </div>
              <div class="item_info item_scct" v-show="activeIndex == 'g_5'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="about_gsmc">{{ $t("home.sc_ct") }}</div>
                  <div class="yinwen_ms" style="padding-left: 1px">
                    SICHUAN SOUTHEAST ASIA DEVELOPMENT CO., LTD
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.sc_ct_text_1") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.sc_ct_text_2") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <div class="item_info item_sczf" v-show="activeIndex == 'g_6'">
                <div class="contant_item">
                  <!-- <a href="https://www.micangmro.com"> -->
                  <div class="about_gsmc">{{ $t("home.sc_zf") }}</div>
                  <div class="yinwen_ms" style="padding-left: 1px">
                    SICHUAN CHAIN DEVELOPMENT CO.,LTD
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.sc_zf_text_1") }}
                  </div>
                  <!-- </a> -->
                  <a
                    class="target_shopping"
                    target="_blank"
                    href="https://www.micangmro.com"
                    >{{ $t("home.mall_entrance") }} ></a
                  >
                </div>
              </div>
              <div class="item_info item_gxmc" v-show="activeIndex == 'g_7'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="about_gsmc">{{ $t("home.gx_xyt") }}</div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.gx_xyt_text_1") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.gx_xyt_text_2") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <div class="item_info item_gzmc" v-show="activeIndex == 'g_8'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="micang_jj" >
                    {{ $t("home.gz_rice") }}
                  </div>
                  <div class="yinwen_ms" style="padding-left: 0">
                    GUANGZHOU GOODCHAINS SCM CO.,LTD
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.gz_rice_text_1") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.gz_rice_text_2") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <div class="item_info item_scmcnx" v-show="activeIndex == 'g_9'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="micang_jj" >
                    {{ $t("home.sc_rice") }}
                  </div>
                  <div class="yinwen_ms" style="padding-left: 0">
                    Sichuan Goodchains Southbound SCM Co., Ltd.
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.sc_rice_text_1") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.sc_rice_text_2") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <div class="item_info item_xb" v-show="activeIndex == 'g_10'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="micang_jj">{{ $t("home.gz_xb") }}</div>
                  <div class="yinwen_ms" style="padding-left: 0">
                    Qunxiang (Guangzhou) Supply Chain Management Co., Ltd.
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.gz_xb_text_1") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <div class="item_info item_gz_international" v-show="activeIndex == 'g_11'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="micang_jj" >
                    {{ $t("home.gz_rice_international") }}
                  </div>
                  <div class="yinwen_ms" style="padding-left: 0">
                    Guangzhou Goodchains International Freight Forwarding
                    Co.LTD.
                  </div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.gz_inter_text_1") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.gz_inter_text_2") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.gz_inter_text_3") }}
                  </div>
                  <div class="info_des">
                    {{ $t("home.gz_inter_text_4") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <div class="item_info item_fsmc" v-show="activeIndex == 'g_12'">
                <div class="contant_item">
                  <!-- <a href="https://en.aerochains.com"> -->
                  <div class="about_gsmc">{{ $t("home.fs_mc") }}</div>
                  <div class="info_des" style="margin-top: 30px">
                    {{ $t("home.fs_mc_text_1") }}
                  </div>
                  <!-- </a> -->
                </div>
              </div>
              <!-- <div
                class="item_info"
                v-show=" activeIndex == 'g_6' || activeIndex == 'g_7' "
              >
                <img
                  :src="companyInfoImg"
                  alt=""
                >
                <a href="https://www.micangmro.com">
                  <div
                    class="contant_item"
                    style="padding-left:30px"
                  >
                    <div class="item_title">{{titleName}}</div>
                    <div class="info_des">{{companyBrief1}}</div>
                    <div class="info_des">{{companyBrief2}}</div>
                  </div>
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import titleImages from "@/components/titleImages";
import aboutBanner1 from "../../../assets/images/about大图1.jpg";
import aboutBanner2 from "../../../assets/images/about大图2.jpg";
import aboutBanner3 from "../../../assets/images/about大图3.jpg";
import aboutBanner4 from "../../../assets/images/about大图2.jpg";
import aboutBanner5 from "../../../assets/images/about大图3.jpg";
import aboutBanner6 from "../../../assets/images/about大图1.jpg";
import aboutBanner7 from "../../../assets/images/about大图3.jpg";
import about_notice1 from "../../../assets/images/about大图1.jpg";
import about_notice2 from "../../../assets/images/about大图3.jpg";

export default {
  name: "Home",
  // components: { titleImages },
  data() {
    return {
      aboutBanner1,
      aboutBanner2,
      aboutBanner3,
      aboutBanner4,
      aboutBanner5,
      aboutBanner6,
      aboutBanner7,
      isShowGenner: true,
      isShowbrief: false,
      titleName: this.$t("home.rice_introduction"),
      companyInfoImg: "",
      companyBrief1: "",
      companyBrief2: "",
      activeIndex: "g_1",
      bannerList: [
        {
          url: aboutBanner1,
          name: "Bru Bee",
        },
        {
          url: aboutBanner2,
          name: "Bru Bee",
        },
        {
          url: aboutBanner3,
          name: "Bru Bee",
        },
        // {
        //   url: aboutBanner4,
        //   name: "Bru Bee"
        // },
        // {
        //   url: aboutBanner5,
        //   name: "Bru Bee"
        // },
        // {
        //   url: aboutBanner6,
        //   name: "Bru Bee"
        // },
        // {
        //   url: aboutBanner7,
        //   name: "Bru Bee"
        // }
      ],
      gennerNavs: [
        { name: this.$t("home.rice_introduction"), id: "g_1" },
        { name: this.$t("home.culture_and_vision"), id: "g_2" },
        { name: this.$t("home.social_responsibility"), id: "g_3" },
      ],
      briefNavs: [
        { name: this.$t("home.gz_airport_rice"), id: "g_4", tId: 1 },
        { name: this.$t("home.sc_ct"), id: "g_5", tId: 2 },
        { name: this.$t("home.sc_zf"), id: "g_6", tId: 3 },
        { name: this.$t("home.gx_xyt"), id: "g_7", tId: 4 },
        { name: this.$t("home.gz_rice"), id: "g_8", tId: 5 },
        { name: this.$t("home.sc_rice"), id: "g_9", tId: 6 },
        { name: this.$t("home.gz_xb"), id: "g_10", tId: 7 },
        { name: this.$t("home.gz_rice_international"), id: "g_11", tId: 8 },
        { name: this.$t("home.fs_mc"), id: "g_12", tId: 9 },
      ],
      content: "",
      isPlatTplePc: true,
      companyinfoList: [
        {
          url: about_notice1,
          des1: "广州空港米仓供应链是一家供应链一体化的企业,借助两方股东的强大资源,实现供应链线上线下采集一体化,同时配备物流一站式服务,致力于发展跨境电商进出口以及东南亚海外市场的综合性企业,是一个集贸易、物流网络和商品集散聚合的服务平台",
          des2: '坚持以"客服为中心,服务最优",为宗旨,秉承"专业、快速、安全"的操作理念，通过互联网技术实现了报关、仓储、国际空运、传统贸易采购、电商采购等进出口业务操作的电子化、转准化和流程化，降低人力成本，提高服务效率，最终导向客户和服务需求的精准化，提高核心竞争力，最大限度地创造价值。',
        },
        {
          url: about_notice2,
          des1: "由成都米仓国际贸易有限公司与大型国有独资企业，成都市金牛国投置业有限公司、成都金牛环城投资管理有限公司合资成立，属国有控股企业。公司主营业务包括外资综合服务、进出口清关服务、海外仓服务、国际物流服务等，并在泰国设立分公司，与东盟各国政府企业来往密切，进一步开括各类进出口商品贸易、服务贸易等业务。同时公司在泰国曼谷设立海外仓库",
          des2: "致力将成都商品带到东南亚，扩大成都与东盟国家间的贸易、物流等方面的深度合作",
        },
        {
          url: about_notice1,
          des1: '致力于打造专业的MRO工业品一站式采购服务平台，在提倡"阳光采购、降本增效大平台、大监督"的理念下，助力企业进行物资管理改革、加强资源整合，促进专业化、集约化管理，打造智能化供应链体系，公司拥有自主知识产权和自主品牌。',
          des2: "四川中发工品科技发展有限公司以互联网科技赋能企业发展，通过米仓工业品平台向国内外大中型工业企业提供产品和服务，包括构建智能化供应链体系、产品推广、进出口贸易、境内外物流、供应链金融、MRO维修维护、备品备件采购、仓储等服务。为工业互联网、工业大数据、工业科技发展贡献力量。",
        },
        {
          url: about_notice1,
          des1: "上海米之谷有限公司，海米之谷国际贸易团队由世界顶级MRO公司供应链高级管理人员组成，包括美国固安捷及澳洲西弄集团，产品供应与质量把控与国际准表全面接轨；具备经营、销售及进出口相关资质，在当前国内外良莠不齐的供应链市场上，为客户提供高质量、高标准的产品。",
          des2: "上海米仓立足华东商业圈，借助米仓消费品平台和米仓工业品平台提供品牌出海，OEM、产品推广、进出口贸易、备品备件采购、境内外物流、仓储等服务，赋能中外企业。",
        },
        {
          url: about_notice1,
          des1: "上海米之谷有限公司，海米之谷国际贸易团队由世界顶级MRO公司供应链高级管理人员组成，包括美国固安捷及澳洲西弄集团，产品供应与质量把控与国际准表全面接轨；具备经营、销售及进出口相关资质，在当前国内外良莠不齐的供应链市场上，为客户提供高质量、高标准的产品。",
          des2: "上海米仓立足华东商业圈，借助米仓消费品平台和米仓工业品平台提供品牌出海，OEM、产品推广、进出口贸易、备品备件采购、境内外物流、仓储等服务，赋能中外企业。",
        },
      ],
    };
  },
  computed: {},
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    this.activeIndex = this.$route.query.gId ? this.$route.query.gId : "g_1";
    if (this.$route.query.gId) {
      if (
        this.activeIndex == "g_1" ||
        this.activeIndex == "g_2" ||
        this.activeIndex == "g_3"
      ) {
        this.isShowGenner = true;
        this.isShowbrief = false;
      } else {
        this.isShowGenner = false;
        this.isShowbrief = true;
      }
    }
    // let { goodchains_guanyumicang } = window;
    // this.goodchainsGuanyumicang = goodchains_guanyumicang;
    // console.log("goodchains_guanyumicang", goodchains_guanyumicang);
    // this.content = goodchains_guanyumicang[0].neirong;
    this.$nextTick(() => {
      let mulPx = 1989 / 391;
      let bannerHeight = document.documentElement.clientWidth / mulPx;
      bannerHeight = bannerHeight + "px";
      console.log(bannerHeight);
      let swpierHeight = document.getElementById("swiper_about");
      console.log(swpierHeight);
      swpierHeight.style.height = bannerHeight;
    });
  },
  methods: {
    tabChangeBtn(item) {
      this.activeIndex = item.id;
      this.titleName = item.name;
      if (item.tId) {
        let index = item.tId - 1;
        // console.log(this.companyinfoList[index].des1);
        this.companyInfoImg = this.companyinfoList[index].url;
        this.companyBrief1 = this.companyinfoList[index].des1;
        this.companyBrief2 = this.companyinfoList[index].des2;
      }
    },
    showTabNav(type) {
      type === 1
        ? (this.isShowGenner = !this.isShowGenner)
        : (this.isShowbrief = !this.isShowbrief);
      if (type == 1 && this.isShowGenner) {
        this.isShowbrief = false;
      } else if (type == 2 && this.isShowbrief) {
        this.isShowGenner = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
::v-deep.el-carousel__arrow {
  i {
    color: #fff;
  }
}
.text_box {
  padding: 30px 0 !important;
}
.about_title {
  font-size: 24px;
  color: #000;
  padding: 20px 0 0 0;
  padding-bottom: 14px;
  margin-bottom: 20px;
  border: 0 !important;
}
.contant_item_zr {
  background: #fff;
  padding: 0 16px;
}
.micang_jj {
  color: #302e2e;
  font-size: 26px;
}
.about_gsmc {
  color: #302e2e;
  font-size: 26px;
}
.yinwen_ms {
  // color: #515151;
  color: rgb(102, 102, 102);
  font-size: 12px;
  margin-top: 4px;
}
.item_mcjj {
  width: 100%;
  min-height: 365px;
  background: url("../../../assets/about_notice/米仓简介.jpg") center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    padding: 40px 0 0 410px;
  }
}
.item_kgmc {
  width: 100%;
  background: url("../../../assets/about_notice/广州空港米仓供应链有限公司.jpg")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
    position: relative;
    .target_shopping {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #e0ac0c;
    }
  }
}
.item_sczf {
  width: 100%;
  background: url("../../../assets/about_notice/四川中发工品科技发展有限公司.jpg")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
    position: relative;
    .target_shopping {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #e0ac0c;
    }
  }
}
.item_fsmc{
  width: 100%;
  background: url("../../../assets/about_notice/fsmc.png")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
    position: relative;
    .target_shopping {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #e0ac0c;
    }
  }
}
.item_gxmc {
  width: 100%;
  background: url("../../../assets/about_notice/广西兴云通国际物流管理有限公司.jpg")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
    position: relative;
    .target_shopping {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #e0ac0c;
    }
  }
}
.item_gzmc {
  width: 100%;
  background: url("../../../assets/about_notice/广州米仓供应链管理有限公司.jpg")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
    position: relative;
    .target_shopping {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #e0ac0c;
    }
  }
}
.item_scmcnx {
  width: 100%;
  background: url("../../../assets/about_notice/四川米仓南向供应链管理有限公司.png")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
    position: relative;
    .target_shopping {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #e0ac0c;
    }
  }
}
.item_scct {
  width: 100%;
  background: url("../../../assets/about_notice/四川成泰南向国际贸易有限公司.jpg")
    center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 40px 0 0 438px;
  }
}
.item_gz_international {
  width: 100%;
  background: url("../../../assets/about_notice/gz_internation.png") center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 0 0 0 438px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.item_xb {
  width: 100%;
  background: url("../../../assets/about_notice/xb.png") center no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    min-height: 365px;
    padding: 0 0 0 438px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.item_qyyj {
  width: 100%;
  min-height: 365px;
  background: url("../../../assets/about_notice/文化与愿景.jpg") center
    no-repeat;
  background-size: 100%;
  .contant_item {
    width: 968px;
    padding: 0 0 0 438px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.backgroud_img {
  width: 100%;
  height: auto;
  background: url("../../../assets/about_notice/空港米仓背景.png") center 100px
    no-repeat;
  background-size: 100%;
  padding: 30px;
}
.header_banner {
  width: 100%;
  height: auto;
}
.left_box {
  // margin-right: 24px;
  ul {
    .left_title {
      background: #d31223;
      color: #fff;
      font-size: 16px;
      padding: 0 25px;
    }
    li {
      width: 100%;
      a {
        font-size: 14px;
        font-family: HarmonyOS_Sans_SC;
        font-weight: 400;
        color: #333;
      }
    }
    .li_active {
      background: #d31223;
      a {
        color: #fff;
      }
    }
  }
}
.left_about_notice {
  min-width: 210px;
  max-width: 260px;
  margin-right: 30px;
}
.company_wh {
  .wh_left {
    width: 100%;
    align-content: center;
    align-items: center;
    > div {
      font-size: 26px;
      color: #5a5a5a;
      span {
        color: #302e2e !important;
        font-size: 18px !important;
      }
      .span_line {
        padding: 12px 20px;
        position: relative;
        color: #302e2e;
        &::after {
          content: " ";
          width: 2px;
          height: 14px;
          background: #5f5f5f;
          position: absolute;
          top: 15px;
          right: 0;
        }
      }
      .span_first {
        padding-left: 0;
      }
      .span_last {
        &::after {
          content: " ";
          width: 0;
          height: 0;
          background: #fff;
          position: absolute;
          top: 15px;
          right: 0;
        }
      }
    }
    .wenhua_yw {
      font-size: 11px;
      margin: 4px 0 23px 0;
      color: rgb(102, 102, 102);
    }
    img {
      width: 136px !important;
      height: 128px !important;
    }
  }
}
@media (max-width: 768px) {
  .left_box {
    padding: 10px;
    ul {
      width: 100%;
      border: none;

      li {
        width: 100%;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border: 2px solid #f0f0f0;
      }
      .li_active {
        color: #d31223;
      }
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  //定义元素 进入页面时和离开页面时的 效果
  transition: opacity 0.5s; // 设置透明度默认是1 1是不透明 0是全透明 全透明相当于消失在页面上 transition css3的过度效果
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0; //设置元素进入 前和离开后都是纯透明状态
}
.navProduct {
  width: 200px;
  background: #333;
  color: #fff;
  height: 60px;
}
::v-deep.el-carousel__arrow {
  i {
    color: #fff;
  }
}
</style>

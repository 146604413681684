<template>
  <div class="inner_container">
    <div class="container">
      <!-- <div v-if="isPlatTplePc">
        <title-images :imgObj="myImageObj" />
      </div> -->
      <el-carousel :interval="5000" arrow="always">
        <div class="swiper_content" id="swiper_about">
          <el-carousel-item
            v-for="(item, index) in serveBannerlist"
            :key="index"
          >
            <img :src="item.url" width="100%" height="100%" />
          </el-carousel-item>
        </div>
      </el-carousel>
      <div class="inner_content">
        <div class="text_box">
          <div class="left_box">
            <p class="business_serve_title">{{ $t("home.main_business") }}</p>
            <ul>
              <li
                v-for="(item, index) in goodchainsMicangzigongsi"
                :key="index"
                :class="item.gId == tabIndex ? 'li_active' : ''"
                @click="tabChangeBtn(item, index)"
              >
                <span></span>
                <a>{{ item.biaoti }}</a>
              </li>
              <!-- <p class="business_serve_title">{{ $t("home.main_projects") }}</p>
              <ul>
                <li
                  v-for="(item, index) in productList"
                  :key="index"
                  :class="item.gId == tabIndex ? 'li_active' : ''"
                  @click="tabChangeBtn(item, index)"
                >
                  <span></span>
                  <a>{{ item.title }}</a>
                </li>
              </ul> -->
            </ul>
            <p class="business_serve_title">{{$t('home.main_programs')}}</p>
            <ul>
              <li
                v-for="(item, index) in productList"
                :key="index"
                :class="item.gId == tabIndex ? 'li_active' : ''"
                @click="tabChangeBtn(item, index)"
              >
                <span></span>
                <a>{{ item.biaoti }}</a>
              </li>
              <!-- <p class="business_serve_title">{{ $t("home.main_projects") }}</p>
              <ul>
                <li
                  v-for="(item, index) in productList"
                  :key="index"
                  :class="item.gId == tabIndex ? 'li_active' : ''"
                  @click="tabChangeBtn(item, index)"
                >
                  <span></span>
                  <a>{{ item.title }}</a>
                </li>
              </ul> -->
            </ul>
          </div>
          <div class="right_box">
            <div v-show="tabIndex === 1">
              <div class="item_top">
                <img
                  src="../../../assets/business_serve/gyp_header.jpg"
                  alt=""
                />
                <div class="company_info">
                  <div class="item_name">{{ $t("home.mc_indu_mall") }}</div>
                  <div class="company_des">
                    <div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        $t("home.mc_indu_mall_1")
                      }}
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ $t("home.mc_indu_mall_2") }}
                  </div>
                  <div class="link_div">
                    <a target="_blank" href="https://www.micangmro.com"
                      ><span>https://www.micangmro.com</span></a
                    ><a target="_blank" href="https://www.micangmro.com">
                      {{ $t("home.mall_entrance") }} ></a
                    >
                  </div>
                </div>
              </div>
              <ul class="business_items">
                <li>
                  <div class="item_title">
                    <span></span>
                    <div>{{ $t("home.mro_goods") }}</div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <ul class="gyp_mro_ul">
                      <li class="gyp_mro_li">
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_1.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.instrument_and_meter") }}
                          </div>
                        </div>
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_2.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.moving_storage") }}
                          </div>
                        </div>
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_3.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.aerohydraulic") }}
                          </div>
                        </div>
                        <div class="icon_div icon_div_last">
                          <img
                            src="../../../assets/business_serve/g_mro_4.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.tool_consumables") }}
                          </div>
                        </div>
                        <div class="icon_div icon_div_last">
                          <img
                            src="../../../assets/business_serve/g_mro_5.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.office_supplies") }}
                          </div>
                        </div>
                      </li>
                      <li class="gyp_mro_li">
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_6.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.building_material") }}
                          </div>
                        </div>
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_7.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.power_transmission") }}
                          </div>
                        </div>
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_8.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.pump_valve_fittings") }}
                          </div>
                        </div>
                        <div class="icon_div icon_div_last">
                          <img
                            src="../../../assets/business_serve/g_mro_9.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.auto_lighting") }}
                          </div>
                        </div>
                        <div class="icon_div icon_div_last">
                          <img
                            src="../../../assets/business_serve/g_mro_10.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.personal_safe") }}
                          </div>
                        </div>
                      </li>
                      <li class="gyp_mro_li" style="margin-bottom: 0">
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_11.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.refrigeration_HVAC") }}
                          </div>
                        </div>
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_12.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.imprisoning_seal") }}
                          </div>
                        </div>
                        <div class="icon_div">
                          <img
                            src="../../../assets/business_serve/g_mro_13.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.laboratory_supplies") }}
                          </div>
                        </div>
                        <div class="icon_div icon_div_last">
                          <img
                            src="../../../assets/business_serve/g_mro_14.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.lubricating_oil_shop") }}
                          </div>
                        </div>
                        <div class="icon_div icon_div_last">
                          <img
                            src="../../../assets/business_serve/g_mro_15.png"
                            alt=""
                          />
                          <div class="mro_text">
                            {{ $t("home.avrasive_metal_work_tool") }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <span></span>
                    <div>{{ $t("home.mro_operation_main") }}</div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list">
                      <li>
                        <img src="../../../assets/img_index/b_s_2.png" alt="" />
                      </li>
                      <li>
                        <img src="../../../assets/img_index/b_s_3.png" alt="" />
                      </li>
                      <li>
                        <img src="../../../assets/img_index/b_s_4.png" alt="" />
                      </li>
                      <li class="mro_hover mro_hover_one">
                        {{ $t("home.mro_operation_text_1") }}
                      </li>
                      <li class="mro_hover mro_hover_two">
                        {{ $t("home.mro_operation_text_2") }}
                      </li>
                      <li class="mro_hover mro_hover_three">
                        {{ $t("home.mro_operation_text_3") }}
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <span></span>
                    <div>{{ $t("home.mro_warehouse_logistics") }}</div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <img
                      class="navigation_img"
                      src="../../../assets/img_index/b_s_5.png"
                      alt=""
                    />
                    <div class="navigation_right">
                      <div class="nv_top">
                        <div class="num_store_col">
                          <span class="item_num">4</span
                          ><span>{{ $t("home.individual") }}</span>
                        </div>
                        <div class="store_list">
                          <div class="store_center">
                            {{ $t("home.regional_center_bin") }}
                          </div>
                          <div>
                            <span
                              >{{ $t("home.chengdu") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.guangzhou") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.shenyang") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.lanzhou") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="nv_top nv_bottom">
                        <div class="num_store_col">
                          <span class="item_num">6</span
                          ><span>{{ $t("home.individual") }}</span>
                        </div>
                        <div class="store_list">
                          <div class="store_center">
                            {{ $t("home.front_service_bin") }}
                          </div>
                          <div>
                            <span
                              >{{ $t("home.hinggan_League") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.zhangjiakou") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.silinghot") }}</span
                            >
                          </div>
                          <div>
                            <span
                              >{{
                                $t("home.silver_city")
                              }}&nbsp;&nbsp;&nbsp;&nbsp;
                              {{
                                $t("home.xiaogan")
                              }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                                $t("home.urumchi")
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-show="tabIndex === 2">
              <div class="item_top bmh_top">
                <img src="../../../assets/img_index/bmh_new.jpg" alt="" />
                <div class="company_info">
                  <div class="item_name">
                    {{ $t("home.rice_popcorn_platform") }}
                  </div>
                  <div class="company_des">
                    <div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {{ $t("home.rice_popcorn_text_1") }}
                    </div>
                    <div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        $t("home.rice_popcorn_text_2")
                      }}
                    </div>
                  </div>
                  <div class="link_div link_div_bmh">
                    <a target="_blank" href="https://www.goodchains365.com"
                      ><span>https://www.goodchains365.com</span></a
                    ><a target="_blank" href="https://www.goodchains365.com">
                      {{ $t("home.mall_entrance") }} ></a
                    >
                  </div>
                </div>
              </div>
              <ul class="business_items bmh_items">
                <li>
                  <div class="item_title">
                    <span></span>
                    <div>
                      {{ $t("home.brand_going_to_sea") }} &nbsp;{{
                        $t("home.preferred_service")
                      }}
                    </div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list brand_list">
                      <li class="brand_li brand_img1">
                        <div class="brand_li_title">
                          {{ $t("home.business_docking") }}
                        </div>
                        <div class="brand_li_bottom">
                          {{ $t("home.build_system_database") }}<br />
                          {{ $t("home.enter_real_information") }}
                        </div>
                      </li>
                      <li class="brand_li brand_img2">
                        <div class="brand_li_title">
                          {{ $t("home.product_screening") }}
                        </div>
                        <div class="brand_li_bottom">
                          {{ $t("home.product_screening_text") }}
                        </div>
                      </li>
                      <li class="brand_li brand_img3">
                        <div class="brand_li_title">
                          {{ $t("home.market_research") }}
                        </div>
                        <div class="brand_li_bottom">
                          {{ $t("home.target_local") }}<br />
                          {{ $t("home.habits_survey") }}<br />
                          {{ $t("home.and_market_share") }}<br />
                          {{ $t("home.position_product") }}
                        </div>
                      </li>
                      <li class="brand_li brand_img4">
                        <div class="brand_li_title">
                          {{ $t("home.preferential_access") }}
                        </div>
                        <div class="brand_li_bottom">
                          {{ $t("home.input_micang_brand") }}<br />
                          {{ $t("home.compliment_special") }}<br />
                          {{ $t("home.match_platform") }}<br />
                          {{ $t("home.enter_traffic") }}<br />
                        </div>
                      </li>
                      <li class="brand_li brand_img5">
                        <div class="brand_li_title">
                          {{ $t("home.marketing_plan") }}
                        </div>
                        <div class="brand_li_bottom">
                          {{ $t("home.diversion_day") }}<br />
                          {{ $t("home.holiday_consumer_days") }}<br />
                          {{ $t("home.establishment_related") }}<br />
                          {{ $t("home.precision_marketing") }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <span></span>
                    <div>
                      {{ $t("home.seller_direct") }} &nbsp;{{
                        $t("home.one_stop_shop")
                      }}
                    </div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list cg_list zc_ul_list">
                      <li class="cg_list_li brand_li cg_img1">
                        <div class="brand_li_title">
                          {{ $t("home.one_click_reservation") }}
                        </div>
                        <div class="brand_li_bottom">
                          <div>{{ $t("home.procurement_sales") }}</div>
                          <div>{{ $t("home.international_logistics") }}</div>
                          <div>{{ $t("home.overseas_storage") }}</div>
                          <div>{{ $t("home.siege_distribution") }}</div>
                        </div>
                      </li>
                      <li class="cg_list_li brand_li cg_img2">
                        <div class="brand_li_title">
                          {{ $t("home.financial_service") }}
                        </div>
                        <div class="brand_li_bottom">
                          <div>{{ $t("home.entitle_pro_lead_time") }}</div>
                          <div>{{ $t("home.low_risk_and_less_worry") }}</div>
                          <div>{{ $t("home.integrated_trade_services") }}</div>
                        </div>
                      </li>
                      <li class="cg_list_li brand_li cg_img3">
                        <div class="brand_li_title">
                          {{ $t("home.selected_products") }}
                        </div>
                        <div class="brand_li_bottom">
                          <div>{{ $t("home.qualification_guarantee") }}</div>
                          <div>{{ $t("home.more_peace_of_mind") }}</div>
                          <div>{{ $t("home.huge_product") }}</div>
                        </div>
                      </li>
                      <li class="cg_list_li brand_li cg_img4">
                        <div class="brand_li_title">
                          {{ $t("home.platform_advantages") }}
                        </div>
                        <div class="brand_li_bottom">
                          <div>{{ $t("home.search_ranking") }}</div>
                          <div>{{ $t("home.inventory_visibility") }}</div>
                          <div>{{ $t("home.platform_subsidies") }}</div>
                          <div>{{ $t("home.traffic_support") }}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <span></span>
                    <div>
                      {{ $t("home.warehouse_logistics") }} &nbsp;{{
                        $t("home.smart_management")
                      }}
                    </div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <img
                      class="navigation_img"
                      src="../../../assets/img_index/bmh_地图.png"
                      alt=""
                    />
                    <div class="navigation_right">
                      <div class="nv_top">
                        <img
                          src="../../../assets/img_index/bmh_10.png"
                          alt=""
                        />
                        <div class="num_store_col">
                          <span class="item_num">4</span
                          ><span>{{ $t("home.individual") }}</span>
                        </div>
                        <div class="store_list">
                          <div class="store_center">
                            {{ $t("home.regional_center_warehouse") }}
                          </div>
                          <div>
                            <span
                              >{{ $t("home.chengdu") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.guangzhou") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.shenyang") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.lanzhou") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="nv_top">
                        <img
                          src="../../../assets/img_index/bmh_11.png"
                          alt=""
                        />
                        <div class="num_store_col">
                          <span class="item_num">3</span
                          ><span>{{ $t("home.individual") }}</span>
                        </div>
                        <div class="store_list">
                          <div class="store_center">
                            {{ $t("home.overseas_service_module") }}
                          </div>
                          <div>
                            <span
                              >{{ $t("home.myanmar") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.thailand") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.malaysia") }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="nv_top">
                        <img
                          src="../../../assets/img_index/bmh_12.png"
                          alt=""
                        />
                        <div class="num_store_col">
                          <span class="item_num">4</span
                          ><span>{{ $t("home.individual") }}</span>
                        </div>
                        <div class="store_list">
                          <div class="store_center">
                            {{ $t("home.ebay_partner_warehouse") }}
                          </div>
                          <div>
                            <span
                              >{{ $t("home.england") }}&nbsp;&nbsp;&nbsp;
                              {{ $t("home.german") }}&nbsp;&nbsp;&nbsp;{{
                                $t("home.america")
                              }}&nbsp;&nbsp;&nbsp;{{
                                $t("home.australia")
                              }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-show="tabIndex === 3">
              <div class="item_top wzf_bannder">
                <img
                  src="../../../assets/business_serve/wzf_header.png"
                  alt=""
                />
                <div class="wzf_info">
                  <div class="item_name">
                    {{ $t("home.rice_outside_platform") }}
                  </div>
                  <div class="company_des">
                    <div>
                      {{ $t("home.rice_outside_platform_text") }}
                    </div>
                  </div>
                </div>
              </div>
              <ul class="business_items wzf_items">
                <li>
                  <div class="item_title_one">
                    <span></span>
                    <div>{{ $t("home.platform_functions") }}</div>
                    <span></span>
                  </div>
                  <div class="spx_img">
                    <ul
                      class="mro_list pt_func_list pt_func_list_two"
                      @mouseover="platMouseover"
                      @mouseleave="platMouseleave"
                    >
                      <li
                        class="mro_pt_li mro_pt_img1"
                        @mouseover.stop="platOnceMouseover"
                      >
                        <div class="pt_left">
                          <div class="pt_title">
                            {{ $t("home.freight_forwarder") }}
                          </div>
                          <img
                            src="../../../assets/img_index/wzft_1.png"
                            alt=""
                          />
                        </div>
                        <div class="pt_right">
                          {{ $t("home.international_logistics") }}<br />
                          {{ $t("home.import_export_special") }}<br />
                          {{ $t("home.cross_border") }}
                        </div>
                      </li>
                      <li
                        class="mro_pt_li mro_pt_img2"
                        @mouseover="platMouseover"
                        @mouseleave="platMouseleave"
                      >
                        <div class="pt_left">
                          <div class="pt_title">{{ $t("home.logistic") }}</div>
                          <img
                            src="../../../assets/img_index/wzft_2.png"
                            alt=""
                          />
                        </div>
                        <div class="pt_right">
                          {{ $t("home.international_express_parcel") }}<br />
                          {{ $t("home.domestic_long_short_routes") }}<br />
                          {{ $t("home.hongkong_transport") }}<br />
                          {{ $t("home.port_feeder") }}<br />
                        </div>
                      </li>
                      <li
                        class="mro_pt_li mro_pt_img3"
                        @mouseover="platMouseover"
                        @mouseleave="platMouseleave"
                      >
                        <div class="pt_left">
                          <div class="pt_title">{{ $t("home.warehouse") }}</div>
                          <img
                            src="../../../assets/img_index/wzft_3.png"
                            alt=""
                          />
                        </div>
                        <div class="pt_right">
                          {{ $t("home.custom_warehouse") }}<br />
                          {{ $t("home.general_warehouse") }}<br />
                          {{ $t("home.overseas_warehouses") }}<br />
                        </div>
                      </li>
                      <li
                        class="mro_pt_li mro_pt_img4"
                        @mouseover="platMouseover"
                        @mouseleave="platMouseleave"
                      >
                        <div class="pt_left">
                          <div class="pt_title">
                            {{ $t("home.customs_affairs") }}
                          </div>
                          <img
                            src="../../../assets/img_index/wzft_4.png"
                            alt=""
                          />
                        </div>
                        <div class="pt_right">
                          1210/9610/9710/9810<br />
                          1039/0110<br />
                          {{ $t("home.general_trade_market") }}<br />
                          {{ $t("home.integrated_national") }}<br />
                        </div>
                      </li>
                      <li
                        class="mro_pt_li mro_pt_img5"
                        @mouseover="platMouseover"
                        @mouseleave="platMouseleave"
                      >
                        <div class="pt_left">
                          <div class="pt_title">{{ $t("home.financial") }}</div>
                          <img
                            src="../../../assets/img_index/wzft_5.png"
                            alt=""
                          />
                        </div>
                        <div class="pt_right">
                          {{ $t("home.supply_chain_picking") }}<br />
                          {{ $t("home.periodical_financing") }}<br />
                        </div>
                      </li>
                      <li
                        class="mro_pt_li mro_pt_img6"
                        @mouseover="platMouseover"
                        @mouseleave="platMouseleave"
                      >
                        <div class="pt_left">
                          <div class="pt_title">{{ $t("home.program") }}</div>
                          <img
                            src="../../../assets/img_index/wzft_6.png"
                            alt=""
                          />
                        </div>
                        <div class="pt_right">
                          {{ $t("home.enterprise_single_window") }}<br />
                          {{ $t("home.total_logistics_solutions") }}<br />
                          {{ $t("home.multimodal") }}<br />
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <div>{{ $t("home.international_freight_forwarding") }}</div>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list gj_list">
                      <li>
                        <img src="../../../assets/img_index/wzf_4.png" alt="" />
                        <div class="bt_arrow">
                          {{ $t("home.maritime_transport_service") }} >
                        </div>
                      </li>
                      <li>
                        <img src="../../../assets/img_index/wzf_5.png" alt="" />
                        <div class="bt_arrow">
                          {{ $t("home.air_transport_services") }} >
                        </div>
                      </li>
                      <li>
                        <img src="../../../assets/img_index/wzf_6.png" alt="" />
                        <div class="bt_arrow">
                          {{ $t("home.china_europe_train") }} >
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <div>{{ $t("home.cross_border_log") }}</div>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list gj_list">
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_71.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.china_tai_transport") }} >
                        </div>
                      </li>
                      <li>
                        <img src="../../../assets/img_index/wzf_8.png" alt="" />
                        <div class="bt_arrow">
                          {{ $t("home.china_hongkong_transport") }} >
                        </div>
                      </li>
                      <li>
                        <img src="../../../assets/img_index/wzf_9.png" alt="" />
                        <div class="bt_arrow">
                          {{ $t("home.china_vietnam") }} >
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <div>{{ $t("home.intelligent_storage") }}</div>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list cg_list">
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_10.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.overseas_warehouse") }} >
                        </div>
                      </li>
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_11.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.hk_warehouse") }} >
                        </div>
                      </li>
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_12.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.bonded_and_general") }} >
                        </div>
                      </li>
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_13.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.operation_of_customs_control") }} >
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <div>{{ $t("home.smart_clearance") }}</div>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list pt_func_list">
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_14.png"
                          alt=""
                        />
                        <div class="bt_arrow">{{ $t("home.O2O_text") }} ></div>
                      </li>
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_15.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.integrated_national") }} >
                        </div>
                      </li>
                      <li>
                        <img
                          src="../../../assets/img_index/wzf_16.png"
                          alt=""
                        />
                        <div class="bt_arrow">
                          {{ $t("home.e_commerce_import_and_export") }} >
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div v-show="tabIndex === 4">
              <div class="item_top">
                <img
                  src="../../../assets/business_serve/hwc_header.jpg"
                  alt=""
                />
                <div class="company_info hwc_info">
                  <div class="item_name">
                    {{ $t("home.rice_oversea_store") }}
                  </div>
                  <div class="company_des">
                    <div>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                        $t("home.rice_oversea_store_text_1")
                      }}
                    </div>
                    <div>
                      {{ $t("home.rice_oversea_store_text_2") }}
                    </div>
                    <div>
                      {{ $t("home.rice_oversea_store_text_3") }}
                    </div>
                  </div>
                </div>
              </div>
              <ul class="business_items hwc_item">
                <li>
                  <div class="spx_img">
                    <div class="hwc_left_img">
                      <el-carousel :intercalcval="5000" arrow="always">
                        <div class="swiper_content" id="swiper_about">
                          <el-carousel-item
                            v-for="(item, index) in hwcDataList"
                            :key="index"
                          >
                            <img :src="item.url" width="100%" height="100%" />
                          </el-carousel-item>
                        </div>
                      </el-carousel>
                    </div>
                    <ul
                      class="mro_list pt_func_list"
                      @mouseover="imgMouseover"
                      @mouseleave="imgMouseleave"
                    >
                      <li
                        class="hwc_li hwc_img1"
                        @mouseover.stop="imgOnceMouseover"
                      >
                        <img id="hwc_img_active" :src="clearPtImg" alt="" />
                        <div class="hwc_li_b1 hwc_li_bottom">
                          <div id="hwc_title_1" class="hwc_title">
                            {{ $t("home.customs_clearance") }}
                          </div>
                          <div id="hwc_title_2" class="hwc_title">
                            CLEARANCE
                          </div>
                        </div>
                      </li>
                      <li
                        class="hwc_li hwc_img2"
                        @mouseover="imgMouseover"
                        @mouseleave="imgMouseleave"
                      >
                        <img
                          class="nomal_img"
                          src="../../../assets/business_serve/hwc_2.png"
                          alt=""
                        />
                        <img
                          class="hover_img"
                          src="../../../assets/business_serve/hwc_ico_2.png"
                          alt=""
                        />
                        <div class="hwc_li_bottom">
                          <div>{{ $t("home.cash_on_delivery") }}</div>
                          <div>COD</div>
                        </div>
                      </li>
                      <li
                        class="hwc_li hwc_img3"
                        @mouseover="imgMouseover"
                        @mouseleave="imgMouseleave"
                      >
                        <img
                          class="nomal_img"
                          src="../../../assets/business_serve/hwc_3.png"
                          alt=""
                        />
                        <img
                          class="hover_img"
                          src="../../../assets/business_serve/hwc_ico_3.png"
                          alt=""
                        />
                        <div class="hwc_li_bottom">
                          <div>{{ $t("home.product_storage") }}</div>
                          <div>STORAGE</div>
                        </div>
                      </li>
                      <li
                        class="hwc_li hwc_img4"
                        @mouseover="imgMouseover"
                        @mouseleave="imgMouseleave"
                      >
                        <img
                          class="nomal_img"
                          src="../../../assets/business_serve/hwc_4.png"
                          alt=""
                        />
                        <img
                          class="hover_img"
                          src="../../../assets/business_serve/hwc_ico_4.png"
                          alt=""
                        />
                        <div class="hwc_li_bottom">
                          <div>{{ $t("home.returns_and_replace") }}</div>
                          <div>RETURN</div>
                        </div>
                      </li>
                      <li
                        class="hwc_li hwc_img5"
                        @mouseover="imgMouseover"
                        @mouseleave="imgMouseleave"
                      >
                        <img
                          class="nomal_img"
                          src="../../../assets/business_serve/hwc_5.png"
                          alt=""
                        />
                        <img
                          class="hover_img"
                          src="../../../assets/business_serve/hwc_ico_5.png"
                          alt=""
                        />
                        <div class="hwc_li_bottom">
                          <div>{{ $t("home.local_delivery") }}</div>
                          <div>DELIVERY</div>
                        </div>
                      </li>
                      <li
                        class="hwc_li hwc_img6"
                        @mouseover="imgMouseover"
                        @mouseleave="imgMouseleave"
                      >
                        <img
                          class="nomal_img"
                          src="../../../assets/business_serve/hwc_6.png"
                          alt=""
                        />
                        <img
                          class="hover_img"
                          src="../../../assets/business_serve/hwc_ico_6.png"
                          alt=""
                        />
                        <div class="hwc_li_bottom">
                          <div>{{ $t("home.sort_and_label") }}</div>
                          <div>LABELING</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="item_title">
                    <div>{{ $t("home.oversea_ware_service") }}</div>
                  </div>
                  <div class="spx_img">
                    <ul class="mro_list wcfw_list">
                      <li class="item item03 wow fadeInUp animated">
                        <div class="wcfw_img wcfw_img_1"></div>
                        <img
                          src="../../../assets/business_serve/一般贸易模式.png"
                          alt=""
                        />
                        <div class="text">
                          <div class="cn">
                            {{ $t("home.general_rade_services") }}
                          </div>
                          <div class="en">General trading</div>
                          <div class="hid fadeInUp20 animated">
                            <p>
                              {{ $t("home.from_china_or_world_to_mc") }}
                              {{ $t("home.mc_pickup") }}
                              {{ $t("home.stored_in_mc") }}
                              {{ $t("home.mi_out_store_send_your_customer") }}
                              {{ $t("home.mc_export_for_china") }}
                            </p>
                          </div>
                        </div>
                        <!-- <div class="hwc_li_b1 hwc_li_bottom">
                          <div class="wcf_title1">一般贸易服务</div>
                          <div class="wcf_title2">General trading</div>
                          <div class="wcf_li_bottom">
                            货物从中国或全球发往米仓海外仓物流服务
                            米仓进行海外仓国家境内提货入仓、口岸清关提货
                            货物存储在米仓海外仓并提供仓储增值服务
                            米仓海外仓直接将货物派送至您在国外的客户
                            米仓按出口中国要求进行采购及发货
                          </div>
                        </div> -->
                      </li>
                      <li class="item item03 wow fadeInUp animated">
                        <div class="wcfw_img wcfw_img_2"></div>
                        <img
                          src="../../../assets/business_serve/海外仓备货模式.png"
                          alt=""
                        />
                        <div class="text">
                          <div class="cn">
                            {{ $t("home.overseas_warehouse_stock") }}
                          </div>
                          <div class="en">
                            Overseas warehouse<br />
                            stocking mode
                          </div>
                          <div class="hid fadeInUp20 animated">
                            <p>
                              1.{{ $t("home.bulk_shipment_overseas") }}<br />
                              2.{{ $t("home.order_received_platforms") }}<br />
                              3.{{ $t("home.pick_up_and_packs_goods") }}<br />
                              4.{{ $t("home.deliver_to_your_oversea") }}
                            </p>
                          </div>
                        </div>

                        <!-- <div class="hwc_li_b1 hwc_li_bottom">
                          <div class="wcf_title1">海外仓备货模式</div>
                          <div class="wcf_title2">Overseas warehouse stocking mode</div>
                          <div class="wcf_li_bottom">
                            1.货物批量发往米仓海外仓<br>
                            2.电商平台上收到订单<br>
                            3.米仓根据订单信息分拣打包商品<br>
                            4.米仓直接派送给您在海外的客户或安排国际物流直邮发往中国
                          </div>
                        </div> -->
                      </li>
                      <li class="item item03 wow fadeInUp animated">
                        <div class="wcfw_img wcfw_img_3"></div>
                        <img
                          src="../../../assets/business_serve/电商小包模式.png"
                          alt=""
                        />
                        <div class="text">
                          <div class="cn">
                            {{ $t("home.e_commerce_parcel") }}
                          </div>
                          <div class="en">e-commerce packet</div>
                          <div class="hid fadeInUp20 animated">
                            <p>
                              1.{{ $t("home.e_commerce_returns") }}<br />
                              2.{{ $t("home.returns_unpacked") }}<br />
                              3.{{ $t("home.re_labeled_for") }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <!-- <div v-show="tabIndex === 5">
              <case-list></case-list>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg1 from "@/assets/business_serve/大图1.jpg";
import bannerImg2 from "@/assets/business_serve/大图2.jpg";
import bannerImg3 from "@/assets/business_serve/大图3.jpg";
import bannerImg4 from "@/assets/business_serve/大图4.jpg";
import hwcImg1 from "@/assets/business_serve/hwc_img1.jpg";
import hwcImg2 from "@/assets/business_serve/hwc_img2.jpg";
import hwcImg3 from "@/assets/business_serve/hwc_img3.jpg";
import hwcImg4 from "@/assets/business_serve/hwc_img4.jpg";
import hoverRedImg from "@/assets/business_serve/7.png";
import hoverImg from "@/assets/business_serve/77.png";
import hoverUnImg from "@/assets/business_serve/hwc_1.png";
import hoverAcImg from "@/assets/business_serve/hwc_ico_1.png";
import platNomalImg from "@/assets/img_index/wzfp_1.jpg";
import platHoverImg from "@/assets/img_index/wzft_1_hover.jpg";
import caseList from "./components/caseList.vue";
export default {
  name: "Home",
  components: {
    caseList,
  },
  data() {
    return {
      activeIndex: 0,
      hoverImg,
      hoverRedImg,
      hoverUnImg,
      hoverAcImg,
      clearPtImg: hoverUnImg,
      platNomalImg,
      platHoverImg,
      serveBannerlist: [
        {
          url: bannerImg1,
          name: "Bru Bee",
        },
        {
          url: bannerImg2,
          name: "Bru Bee",
        },
        {
          url: bannerImg3,
          name: "Bru Bee",
        },
        {
          url: bannerImg4,
          name: "Bru Bee",
        },
      ],
      hwcDataList: [
        {
          url: hwcImg1,
          name: "Bru Bee",
        },
        {
          url: hwcImg2,
          name: "Bru Bee",
        },
        {
          url: hwcImg3,
          name: "Bru Bee",
        },
        {
          url: hwcImg4,
          name: "Bru Bee",
        },
      ],
      // productList: [
      //   {
      //     link: "/",
      //     title: this.$t("home.rice_industrial_platform"),
      //     gId: 1,
      //   },
      //   {
      //     link: "/",
      //     title: this.$t("home.rice_popcorn_platform"),
      //     gId: 2,
      //   },
      //   {
      //     link: "/",
      //     title: this.$t("home.postal_declaration"),
      //     gId: 101,
      //   },
      // ],
      productList: [
        {
          link: "/",
          biaoti: this.$t("home.rice_industrial_platform"),
          gId: 1,
        },
        {
          link: "/",
          biaoti: this.$t("home.rice_popcorn_platform"),
          gId: 2,
        },
      ],
      goodchainsMicangzigongsi: [
        {
          link: "/",
          biaoti: this.$t("home.rice_outside_platform"),
          gId: 3,
        },
        {
          link: "/",
          biaoti: this.$t("home.rice_oversea_store"),
          gId: 4,
        },
        // {
        //   link: "/",
        //   biaoti: this.$t("home.product_case"),
        //   gId: 5,
        // },
        //         {
        //   link: "/",
        //   biaoti: this.$t("home.air_road_service"),
        //   gId: 6,
        // },
        // {
        //   link: "/",
        //   biaoti: this.$t("home.cn_lao_liner"),
        //   gId: 7,
        // },
        // {
        //   link: "/",
        //   biaoti: this.$t("home.gz_airport_forward"),
        //   gId: 8,
        // },
        // {
        //   link: "/",
        //   biaoti: this.$t("home.dx_port_terminal"),
        //   gId: 9,
        // },
        // {
        //   link: "/",
        //   biaoti: this.$t("home.fs_port_treminal"),
        //   gId: 10,
        // },
      ],
      content: "",
      isPlatTplePc: true,
      tabIndex: 3,
    };
  },
  computed: {},
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      let mulPx = 1989 / 391;
      let bannerHeight = document.documentElement.clientWidth / mulPx;
      bannerHeight = bannerHeight + "px";
      console.log(bannerHeight);
      let swpierHeight = document.getElementById("swiper_about");
      console.log(swpierHeight);
      swpierHeight.style.height = bannerHeight;
    });
    let { goodchains_micangzigongsi } = window;
    // if (goodchains_micangzigongsi) this.goodchainsMicangzigongsi = goodchains_micangzigongsi;

    console.log("goodchains_micangzigongsi", goodchains_micangzigongsi);
    this.tabIndex = this.$route.query.tabId
      ? Number(this.$route.query.tabId)
      : 3;
    this.activeIndex = this.tabIndex - 1;
    // this.content = goodchains_micangzigongsi[0].neirong;
  },
  methods: {
    tabChangeBtn(item, index) {
      this.activeIndex = index;
      this.tabIndex = item.gId;
      // this.goodchainsMicangzigongsi.map(item2 => {
      //   if (item.biaoti == item2.biaoti) {
      //     this.content = item2.neirong;
      //   }
      // });
    },
    platOnceMouseover() {
      this.$nextTick(() => {
        let hoverplatImg = document.querySelector(".mro_pt_img1");
        hoverplatImg.style.background = "url(" + this.platNomalImg + ") center";
      });
    },
    platMouseover() {
      this.$nextTick(() => {
        let hoverplatImg = document.querySelector(".mro_pt_img1");
        hoverplatImg.style.background = "url(" + this.platHoverImg + ") center";
      });
    },
    platMouseleave() {
      this.$nextTick(() => {
        let hoverplatImg = document.querySelector(".mro_pt_img1");
        console.log(hoverplatImg);
        console.log(this.platNomalImg);
        hoverplatImg.style.background = "url(" + this.platNomalImg + ") center";
      });
    },
    imgOnceMouseover() {
      this.clearPtImg = this.hoverUnImg;
      this.$nextTick(() => {
        let hoverImg = document.querySelector(".hwc_img1");
        hoverImg.style.background = "url(" + this.hoverRedImg + ") center";
        let textColor = document.getElementById("hwc_title_1");
        let textColor2 = document.getElementById("hwc_title_2");
        textColor.style.color = "#fff";
        textColor2.style.color = "#fff";
      });
    },
    imgMouseover() {
      this.clearPtImg = this.hoverAcImg;
      this.$nextTick(() => {
        let hoverImg = document.querySelector(".hwc_img1");
        hoverImg.style.background = "url(" + this.hoverImg + ") center";
        let textColor = document.getElementById("hwc_title_1");
        let textColor2 = document.getElementById("hwc_title_2");
        textColor.style.color = "#959595";
        textColor2.style.color = "#959595";
      });
    },
    imgMouseleave() {
      this.clearPtImg = this.hoverUnImg;
      this.$nextTick(() => {
        let hoverImg = document.querySelector(".hwc_img1");
        hoverImg.style.background = "url(" + this.hoverRedImg + ") center";
        let textColor = document.getElementById("hwc_title_1");
        let textColor2 = document.getElementById("hwc_title_2");
        textColor.style.color = "#fff";
        textColor2.style.color = "#fff";
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
::-webkit-scrollbar {
  display: none;
}
.left_box {
  width: 200px;
  margin-right: 24px;
  .business_serve_title {
    font-size: 24px;
    padding-bottom: 14px;
    padding-top: 20px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
  }
  ul {
    .left_title {
      background: #d31223;
      color: #fff;
      font-size: 16px;
      padding: 0 25px;
    }
    li {
      width: 100%;
      // min-height: 42px;
      // line-height: 42px;
      border-bottom: 2px solid #f0f0f0;
      overflow: hidden;
      padding: 0 25px !important;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #333;
        margin-right: 6px;
        border: 1px solid #ccc;
      }
      a {
        padding: 10px 0;
        font-size: 14px;
        font-family: HarmonyOS_Sans_SC;
        font-weight: 400;
        color: #333;
      }
    }
    .li_active {
      background: #d31223;
      span {
        background-color: #fff !important;
      }
      a {
        color: #fff;
      }
    }
  }
}

.right_box {
  width: calc(100% - 200px);
  padding: 0;
  .item_top {
    margin-bottom: 60px;
    position: relative;
    img {
      width: 90%;
    }
    .company_info {
      width: 50%;
      max-height: 320px;
      position: absolute;
      left: 50%;
      top: 0;
      opacity: 1;
      padding-top: 40px;
      padding-right: 60px;
      .item_name {
        text-align: right;
        font-size: 24px;
        margin-bottom: 30px;
        color: #242424;
      }
      .company_des {
        max-height: 240px;
        overflow-x: hidden;
        overflow-y: auto;
        line-height: 24px;
        color: #242424;
        text-align: left;
        > div {
          color: #242424;
        }
      }
      .link_div {
        width: 100%;
        position: absolute;
        bottom: -50px;
        display: flex;
        padding-right: 60px;
        justify-content: space-between;
        span {
          font-size: 14px;
          color: #e0ac0c;
        }
        a {
          font-size: 14px;
          color: #e0ac0c;
        }
      }
      .link_div_bmh {
        bottom: -66px !important;
      }
    }
    .hwc_info {
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      padding: 46px 0 0 30px;
      text-align: right;
    }
  }
  .bmh_top {
    img {
      width: 90%;
    }
    .company_info {
      padding-top: 60px;
    }
  }
  .wzf_bannder {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      width: 686px;
      height: auto;
    }
    .wzf_info {
      color: #242424;
      .item_name {
        color: #242424;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .company_des {
        > div {
          color: #242424;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  .business_items {
    img {
      width: 100%;
      height: auto;
    }
    li {
      margin-bottom: 60px;
      .item_title {
        text-align: center;
        padding: 10px 0 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          width: 130px;
          height: 2px;
          background: #333;
          margin: 0 16px;
        }
        > div {
          font-size: 30px;
          color: #515151;
          font-weight: 600;
        }
      }
    }
    .spx_img {
      display: flex;
      position: relative;
      .navigation_img {
        width: 506px;
      }
      .navigation_right {
        width: 57%;
        // height: 290px;
        position: absolute;
        bottom: 0;
        left: 45%;
        background: url("../../../assets/img_index/gyp_footer.jpg");
        background-size: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 62px;
        .nv_top {
          display: flex;
          margin-bottom: 15px;
          margin-top: 15px;
          align-items: center;
          img {
            width: 50px;
            height: 38px;
          }
          .num_store_col {
            margin-left: 20px;

            .item_num {
              font-size: 44px;
              color: #d90000;
            }
            span {
              color: #d90000;
            }
          }
          .store_list {
            padding-left: 16px;
            margin-left: 16px;
            border-left: 1px solid #ccc;
            .store_center {
              font-size: 16px;
              color: #000;
            }
            span {
              font-size: 12px;
              color: #000;
            }
          }
        }
        .nv_bottom {
          .num_store_col {
            margin-left: 20px;

            .item_num {
              font-size: 44px;
              color: blue;
            }
            span {
              color: blue;
            }
          }
        }
      }
      .mro_list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        li {
          width: 32.7%;
          margin-bottom: 4px;
          img {
            width: 100%;
            // height: auto;
            height: 259px;
          }
        }
        .mro_hover {
          padding: 43px 25px;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          display: flex;
          align-items: center;
        }
        .mro_hover_one {
          width: 345px;
          height: 187px;
          overflow-y: auto;
          background: url("../../../assets/img_index/b_s_22.png") center
            no-repeat;
          background-size: 100%;
        }
        .mro_hover_two {
          width: 345px;
          height: 187px;
          background: url("../../../assets/img_index/b_s_33.png") center
            no-repeat;
          background-size: 100%;
        }
        .mro_hover_three {
          width: 345px;
          height: 187px;
          background: url("../../../assets/img_index/b_s_44.png") center
            no-repeat;
          background-size: 100%;
        }
      }

      .brand_list {
        li {
          width: 19%;
          margin-bottom: 0;
          transform-style: preserve-3d;
          transition: 1s;
          box-sizing: border-box;
          padding-top: 66px;
          cursor: pointer;
          border-radius: 5px;
          &:hover {
            // transform: scale(1.1);
            z-index: 1;
            background-size: 116%;
          }
        }
        .brand_img1 {
          width: 206px;
          // height: 286px;
          background: url("../../../assets/img_index/bmh_1.png") center
            no-repeat;
          background-size: 100%;
        }
        .brand_img2 {
          width: 206px;
          // height: 286px;
          background: url("../../../assets/img_index/bmh_2.png") center
            no-repeat;
          background-size: 100%;
        }
        .brand_img3 {
          width: 206px;
          // height: 286px;
          background: url("../../../assets/img_index/bmh_3.png") center
            no-repeat;
          background-size: 100%;
        }
        .brand_img4 {
          width: 206px;
          // height: 286px;
          background: url("../../../assets/img_index/bmh_4.png") center
            no-repeat;
          background-size: 100%;
        }
        .brand_img5 {
          width: 206px;
          // height: 286px;
          background: url("../../../assets/img_index/bmh_5.png") center
            no-repeat;
          background-size: 100%;
        }
      }
      .brand_li {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        .brand_li_title {
          font-size: 20px;
          color: #fff;
          padding-bottom: 34px;
          margin-bottom: 34px;
          border-bottom: 1px dashed #fff;
          width: 100%;
          text-align: center;
        }
        .brand_li_bottom {
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 20px;
          div {
            font-size: 16px;
            color: #fff;
            text-align: center;
            line-height: 26px;
          }
        }
      }
      .zc_ul_list {
        li {
          transform-style: preserve-3d;
          transition: 1s;
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            // transform: scale(1.1);
            // z-index: 1;
            background-size: 116%;
          }
        }
      }
      .cg_list {
        li {
          width: 24.3%;
          margin-bottom: 0;
          border-radius: 8px;
        }
        .cg_img1 {
          width: 256px;
          // height: 331px;
          background: url("../../../assets/img_index/bmh_6.png") center
            no-repeat;
          background-size: 100%;
        }
        .cg_img2 {
          width: 256px;
          // height: 331px;
          background: url("../../../assets/img_index/bmh_7.png") center
            no-repeat;
          background-size: 100%;
        }
        .cg_img3 {
          width: 256px;
          // height: 331px;
          background: url("../../../assets/img_index/bmh_8.png") center
            no-repeat;
          background-size: 100%;
        }
        .cg_img4 {
          width: 256px;
          // height: 331px;
          background: url("../../../assets/img_index/bmh_9.png") center
            no-repeat;
          background-size: 100%;
        }
      }
      .pt_func_list {
        li {
          width: 32.7%;
        }
      }
      .pt_func_list_two {
        li {
          height: 138px !important;
          img {
            height: 100% !important;
          }
        }
        .mro_pt_li {
          width: 345px;
          // height: 183px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          .pt_left {
            padding: 0 24px;
            .pt_title {
              font-size: 24px;
              color: #fff;
              margin-bottom: 6px;
              margin-top: 14px;
            }
            img {
              width: 43px !important;
              height: 34px !important;
            }
          }
          .pt_right {
            max-width: 154px;
            padding-right: 16px;
            line-height: 20px;
            font-size: 12px;
            color: #fff;
          }
        }
        .mro_pt_img1 {
          background: url("../../../assets/img_index/wzfp_1.jpg") center
            no-repeat;
          background-size: 100%;
        }
        .mro_pt_img2 {
          background: url("../../../assets/img_index/wzfp_22.jpg") center
            no-repeat;
          background-size: 100%;
          &:hover {
            background: url("../../../assets/img_index/wzfp_2.jpg") center
              no-repeat;
          }
        }
        .mro_pt_img3 {
          background: url("../../../assets/img_index/canc_wms.jpg") center
            no-repeat;
          background-size: 100%;
          &:hover {
            background: url("../../../assets/img_index/wzfp_3.jpg") center
              no-repeat;
          }
        }
        .mro_pt_img4 {
          background: url("../../../assets/img_index/wzfp_44.jpg") center
            no-repeat;
          background-size: 100%;
          &:hover {
            background: url("../../../assets/img_index/wzfp_4.jpg") center
              no-repeat;
          }
        }
        .mro_pt_img5 {
          background: url("../../../assets/img_index/金融.jpg") center no-repeat;
          background-size: 100%;
          &:hover {
            background: url("../../../assets/img_index/wzfp_55.jpg") center
              no-repeat;
          }
        }
        .mro_pt_img6 {
          background: url("../../../assets/img_index/方案.jpg") center no-repeat;
          background-size: 100%;
          &:hover {
            background: url("../../../assets/img_index/wzfp_66.jpg") center
              no-repeat;
          }
        }
      }

      .gj_list {
        li {
          width: 32.7%;
        }
      }
      .wcfw_list {
        li {
          width: 33.333%;
        }
        .item.item.item03 {
          width: 350px;
          // height: 289px;
          text-align: center;
          padding: 60px 0 0;
        }

        .index .page2 .item {
          background: no-repeat center;
          background-size: cover;
          position: relative;
          overflow: hidden;
          -webkit-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
        }
        .fadeInUp {
          -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
        }
        .item:hover:after {
          opacity: 0.75;
          filter: alpha(opacity=75);
        }
        .item:hover .hid {
          display: block;
        }

        .item:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #010101;
          opacity: 0;
          filter: alpha(opacity=0);
          transition: opacity 0.4s ease-out;
          -webkit-transition: opacity 0.4s ease-out;
        }
        .animated {
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }

        .item.item03 .wcfw_img_1 {
          width: 351px;
          background-image: url("../../../assets/business_serve/3.png");
        }
        .item.item03 .wcfw_img_2 {
          width: 351px;
          background-image: url("../../../assets/business_serve/4.png");
        }
        .item.item03 .wcfw_img_3 {
          width: 351px;
          background-image: url("../../../assets/business_serve/5.png");
        }
        .item .cn {
          font-size: 19px;
          line-height: 30px;
          color: #fff;
          position: relative;
          z-index: 2;
          -webkit-transition: all 0.4s;
          transition: all 0.4s;
        }
        .item .en {
          font-family: "RAJDHANI_BOLD";
          font-size: 18px;
          line-height: 1;
          color: #fff;
          letter-spacing: -1px;
          position: relative;
          z-index: 2;
          -webkit-transition: all 0.4s;
          transition: all 0.4s;
        }
        .index .page2 .item.item03 .hid {
          left: 295px;
        }

        // .item .hid {
        //   display: none;
        //   position: relative;
        //   top: 140px;
        //   // left: 28px;
        //   padding: 0 30px;
        //   z-index: 2;
        //   animation-delay: 1s;
        //   -webkit-animation-delay: 0.4s;
        // }
        .fadeInUp20 {
          -webkit-animation-name: fadeInUp20;
          animation-name: fadeInUp20;
        }
        .animated {
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
        .item .wcfw_img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          background: no-repeat center;
          background-size: cover;
          transition: background-image 0.4s ease-out;
          -webkit-transition: background-image 0.4s ease-out;
        }
        .item .hid p {
          font-size: 12px;
          line-height: 18px;
          color: #fff;
          border-bottom: 0 !important;
          text-align: left;
          padding: 0 30px;
          visibility: hidden;
          position: relative;
          z-index: 2;
          -webkit-transition: all 0.4s;
          transition: all 1s;
        }
        .item {
          width: 352px;
          // height: 289px;
          animation-delay: 0.7s;
          visibility: visible;
          animation-name: fadeInUp;
          cursor: pointer;
          position: relative;
          img {
            width: 78px !important;
            height: 78px !important;
            margin-bottom: 30px;
            z-index: 1;
          }
          .cn {
            font-size: 20px;
            color: #fff;
            text-align: center;
          }
          .en {
            font-size: 16px;
            color: #fff;
            text-align: center;
          }

          &:hover {
            // transform: translatez(0) rotateX(360deg) scale(1);
            .cn {
              transform: translateY(-110px);
            }
            .en {
              transform: translateY(-110px);
            }
            p {
              visibility: visible;
              transform: translateY(-80px);
            }
            &::after {
              opacity: 0.75;
            }
            .wcf_li_bottom {
              display: block !important;
            }
            img {
              visibility: hidden !important;
            }
          }
          &::after {
            background-color: #010101;
          }
        }
      }
      .gyp_mro_ul {
        width: 100%;
        li {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 46px;
          img {
            width: 71px;
            height: 55px;
          }
          .icon_div {
            width: 116px;
            text-align: center;
            padding-right: 46px;
          }
          .icon_div_last {
            padding-right: 0 !important;
          }
          .mro_text {
            transform: translateY(6px);
            color: #525252;
          }
        }
      }
    }
  }
  .bmh_items {
    li {
      .spx_img {
        align-items: center;
        .navigation_img {
          width: 624px;
        }
        .navigation_right {
          width: 40%;
          position: static;
          background: #fff;
          .nv_top {
            margin-top: 6px;
            align-items: center;
            img {
              width: 42px;
              height: 42px;
            }
            .num_store_col {
              .item_num {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }
  .wzf_items {
    li {
      margin-bottom: 10px;
      .item_title {
        text-align: left;
        justify-content: start;
        padding: 16px 0;
        > div {
          font-size: 26px;
          font-weight: 400;
        }
      }
      .item_title_one {
        text-align: center;
        padding: 10px 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: inline-block;
          width: 130px;
          height: 2px;
          background: #333;
          margin: 0 16px;
        }
        > div {
          font-size: 30px;
          color: #515151;
          font-weight: 600;
        }
      }
    }
  }
  .hwc_item {
    .spx_img {
      justify-content: space-between;
    }
    .hwc_left_img {
      width: 435px;
      height: 298px;
      img {
        width: 100%;
        height: auto;
      }
      ::v-deep.el-carousel__container {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    li {
      margin-bottom: 10px;
      .item_title {
        text-align: left;
        justify-content: start;
        padding: 16px 0;
        > div {
          font-size: 26px;
          font-weight: 400;
        }
      }
    }
    .pt_func_list {
      width: 613px !important;
      li {
        margin-bottom: 6px !important;
        width: 32.5% !important;
      }
      .hwc_li {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 44px !important;
          height: 38px !important;
          margin-bottom: 20px;
        }
        .hwc_li_b1 {
          text-align: center;
          .hwc_title {
            color: #fff;
            font-size: 14px;
          }
        }
        .hwc_li_bottom {
          text-align: center;
          > div {
            color: #959595;
          }
        }
        // .nomal_img{
        //   display: block;
        // }
        .hover_img {
          display: none;
        }
      }
      .hwc_img1 {
        width: 199px;
        height: 147px;
        background: url("../../../assets/business_serve/7.png") center no-repeat;
        background-size: 100%;
      }
      .hwc_img2 {
        width: 199px;
        height: 147px;
        background: url("../../../assets/business_serve/77.png") center
          no-repeat;
        background-size: 100%;
        &:hover {
          background: url("../../../assets/business_serve/7.png") center
            no-repeat;
          .hwc_li_bottom > div {
            color: #fff !important;
          }
          .hwc_img1 {
            background: url("../../../assets/business_serve/77.png") center
              no-repeat !important;
          }
          .hover_img {
            display: block !important;
          }
          .nomal_img {
            display: none;
          }
        }
      }
      .hwc_img3 {
        width: 199px;
        height: 147px;
        background: url("../../../assets/business_serve/77.png") center
          no-repeat;
        background-size: 100%;
        &:hover {
          background: url("../../../assets/business_serve/7.png") center
            no-repeat;
          .hwc_li_bottom > div {
            color: #fff !important;
          }
          .hwc_img1 {
            background: url("../../../assets/business_serve/77.png") center
              no-repeat !important;
          }
          .hover_img {
            display: block !important;
          }
          .nomal_img {
            display: none;
          }
        }
      }
      .hwc_img4 {
        width: 199px;
        height: 147px;
        background: url("../../../assets/business_serve/77.png") center
          no-repeat;
        background-size: 100%;
        &:hover {
          background: url("../../../assets/business_serve/7.png") center
            no-repeat;
          .hwc_li_bottom > div {
            color: #fff !important;
          }
          .hwc_img1 {
            background: url("../../../assets/business_serve/77.png") center
              no-repeat !important;
          }
          .hover_img {
            display: block !important;
          }
          .nomal_img {
            display: none;
          }
        }
      }
      .hwc_img5 {
        width: 199px;
        height: 147px;
        background: url("../../../assets/business_serve/77.png") center
          no-repeat;
        background-size: 100%;
        &:hover {
          background: url("../../../assets/business_serve/7.png") center
            no-repeat;
          .hwc_li_bottom > div {
            color: #fff !important;
          }
          .hwc_img1 {
            background: url("../../../assets/business_serve/77.png") center
              no-repeat !important;
          }
          .hover_img {
            display: block !important;
          }
          .nomal_img {
            display: none;
          }
        }
      }
      .hwc_img6 {
        width: 199px;
        height: 147px;
        background: url("../../../assets/business_serve/77.png") center
          no-repeat;
        background-size: 100%;
        &:hover {
          background: url("../../../assets/business_serve/7.png") center
            no-repeat;
          .hwc_li_bottom > div {
            color: #fff !important;
          }
          .hwc_img1 {
            background: url("../../../assets/business_serve/77.png") center
              no-repeat !important;
          }
          .hover_img {
            display: block !important;
          }
          .nomal_img {
            display: none;
          }
        }
      }
    }
  }
}
.bt_arrow {
  font-weight: 600;
  color: #626262;
}
@media (max-width: 768px) {
  .left_box {
    padding: 10px;
    ul {
      width: 100%;
      border: none;

      li {
        width: 100%;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border: 2px solid #f0f0f0;
      }
      .li_active {
        color: #d31223;
      }
    }
  }
}
::v-deep.el-carousel__arrow {
  i {
    color: #fff;
  }
}
</style>

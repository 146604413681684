<template>
  <div class="inner_container">
    <div class="container">
      <!-- <div v-if="isPlatTplePc">
        <title-images :imgObj="myImageObj" />
      </div> -->
      <img class="header_banner" :src="bannerImg" alt="" />
      <div class="inner_content" style="margin-top: 30px">
        <div class="new_notice_top">
          <div class="new_center_swiper">
            <el-carousel :interval="5000" arrow="always">
              <div class="swiper_content" id="swiper_about">
                <el-carousel-item
                  v-for="(item, index) in newCenterDataList"
                  :key="index"
                >
                  <img
                    :src="item.url"
                    @click="goNewcenterDetail(item)"
                    width="100%"
                  />
                  <div class="swiper_tip">{{ item.name }}</div>
                </el-carousel-item>
              </div>
            </el-carousel>
          </div>

          <div class="new_item" @click="goDetil(newItem)">
            <div class="title">
              {{ newItem.title }}
            </div>
            <p v-if="newItem.createTime">
              {{ newItem.createTime | createformat() }}
            </p>
            {{ newItem.descript }}
          </div>
        </div>
        <div class="text_box box_new_center">
          <div class="search_div">
            <el-input
              @input="searchList"
              :placeholder="$t('home.please_enter_keywords')"
              v-model="keyWord"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div class="right_box">
            <ul>
              <li
                v-for="(item, index) in goodchainsXinwenzhongxiaong[pages.page]"
                :key="index"
                @click="goDetil(item)"
              >
                <div class="content">
                  <!-- <div class="images">
                    <title-images :imgObj="item.myImageObj" />
                  </div> -->
                  <div class="txt">
                    <div class="date" v-if="newItem.createTime">
                      {{ item.createTime | createformat() }}
                    </div>
                    <div class="new_list">
                      <div class="title">
                        {{ item.title }} <i class="el-icon-arrow-right"></i>
                      </div>
                      <div class="text">
                        {{ item.descript }}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pages.total"
                :page-size="5"
                :hide-on-single-page="false"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerImg from "@/assets/images/state_1.jpg";
import hwcImg1 from "@/assets/images/state_2.jpg";
import hwcImg2 from "@/assets/images/state_22.jpg";
import hwcImg3 from "@/assets/images/state_222.jpg";
import { splitArray } from "@/common/splitArray";
import { getWebsiteDynamic } from "@/api/websiteDynamic";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      keyWord: "",
      pages: {
        total: 0,
        page: 0,
        // pageSize: 5
      },
      bannerImg,
      newCenterDataList: [
        {
          url: hwcImg1,
          index: 1,
          name: this.$t("home.mc_newcenter_1"),
        },
        {
          url: hwcImg2,
          index: 2,
          name: this.$t("home.mc_newcenter_2"),
        },
        {
          url: hwcImg3,
          index: 3,
          name: this.$t("home.mc_newcenter_3"),
        },
      ],
      goodchainsXinwenzhongxiaong: [],
      navList: [
        {
          link: "/",
          title: "新闻中心",
        },
      ],
      contnet: "",
      isPlatTplePc: true,
      initTableList: [],
      newItem: {},
      goodchains_xinwenzhongxiaong: [],
      publicPath: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    locale() {
      return localStorage.getItem("language") || "zh";
    },
  },
  filters: {
    createformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  methods: {
    getList() {
      const data = {
        sort: "sort,desc",
      };
      getWebsiteDynamic(data).then((res) => {
        const { content, totalElements } = res;
        sessionStorage.setItem("newList", JSON.stringify(content));
        switch (this.locale) {
          case "en":
            this.goodchains_xinwenzhongxiaong = content.map((item) => {
              // if (item.infoEn) {
              //   const infoEn = JSON.parse(item.infoEn) || {};
              //   infoEn["id"] = item.id;
              //   return infoEn;
              // } else {
                const info = item || {};
                return info;
              // }
            });
            break;
          case "zh":
            this.goodchains_xinwenzhongxiaong = content;
            break;
          default:
            this.goodchains_xinwenzhongxiaong = content;
            break;
        }

        this.pages.total = content.length || 0;
        this.goodchains_xinwenzhongxiaong.map((item) => {
          item.myImageObj = {
            url: this.publicPath + "/file/图片/" + item.photoName,
            width: "100%",
            height: "100%",
          };
        });
        this.initTableList = this.goodchains_xinwenzhongxiaong;
        this.newItem = this.goodchains_xinwenzhongxiaong[0];
        const len = this.goodchains_xinwenzhongxiaong.length;
        const arr = this.goodchains_xinwenzhongxiaong.slice(1, len);
        this.goodchainsXinwenzhongxiaong = splitArray(arr, 5);
      });
    },
    currentChange(page) {
      this.pages.page = page - 1;
    },
    goNewcenterDetail(item) {
      let objItem =
        item.index == 1
          ? this.goodchainsXinwenzhongxiaong[0][item.index]
          : this.newItem;
      this.$router.push({
        path: "/newCenterDetil",
        query: {
          item: objItem.id,
        },
      });
    },
    goDetil(item) {
      this.$router.push({
        path: "/newCenterDetil",
        query: {
          id: item.id,
        },
      });
    },
    tabBtn(obj) {
      this.content = obj.item.neirong;
    },
    searchList(text) {
      if (text == "") {
        this.goodchainsXinwenzhongxiaong[0] = JSON.parse(
          JSON.stringify(this.initTableList)
        );
        return;
      }
      let newArr = this.goodchainsXinwenzhongxiaong[0].filter((item) => {
        console.log(text);
        // if (
        //   isNaN(text) &&
        //   item.biaoti &&
        //   item.biaoti.indexOf(text.trim()) == -1
        // ) {
        //   return item.biaoti.indexOf(text.trim()) != -1;
        // } else if (
        //   isNaN(text) &&
        //   item.jianjie &&
        //   item.biaoti.indexOf(text.trim()) == -1
        // ) {
        //   return item.jianjie.indexOf(text.trim()) != -1;
        // }
        return (
          item.title.indexOf(text.trim()) != -1 ||
          item.jianjie.indexOf(text.trim()) != -1 ||
          false
        );
      });
      console.log(newArr);

      this.goodchainsXinwenzhongxiaong[0] = newArr;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
.header_banner {
  width: 100%;
  height: auto;
}
.new_notice_top {
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  border-radius: 4px 4px 0 0;
  .new_center_swiper {
    width: 395px;
    height: 235px;
    margin-right: 30px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .swiper_tip {
      position: absolute;
      left: 16px;
      bottom: 6px;
      color: #fff;
      font-size: 14px;
      z-index: 9;
    }
  }
  img {
    width: 395px;
    height: 235px;
    margin-right: 30px;
  }
  .new_item {
    flex: 1;
    padding-right: 60px;
    cursor: pointer;
    .title {
      font-size: 19px;
      font-weight: bold;
      color: #e40000;
      margin-bottom: 6px;
    }
    p {
      font-size: 18px;
      color: #797979;
      margin-bottom: 12px;
      font-family: 方正汉真广标简体;
      margin-top: 1px;
    }
  }
}
.box_new_center {
  width: auto !important;
  display: flex;
  padding: 0 !important;
  .search_div {
    width: 398px;
    padding: 30px 38px 0 0;
  }
}
.right_box {
  ul {
    li {
      cursor: pointer;
      padding: 28px 0;
      border-left: 1px solid #eee;
      border-bottom: 1px solid #eee;
      &:hover {
        background-color: #f0f2f5;
        border-left: 1px solid #f0f2f5;
        border-bottom: 1px solid #f0f2f5;
        .title {
          color: #e40000;
        }
      }
      .new_list {
        flex: 1;
        padding-left: 20px;
      }
    }
  }
}
::v-deep .el-carousel__container {
  height: 235px;
}

.content {
  display: flex;
  .images {
    width: 398px;
    height: 140px;
  }
  .txt {
    margin-left: 20px;
    margin-bottom: 0 !important;
    .title {
      font-size: 19px;
      font-weight: bold;
      color: #333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 18px;
        font-weight: 600;
        margin-right: 40px;
      }
    }
    .text {
      margin-top: 21px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      padding-right: 136px;
    }
    .date {
      // margin-top: 4px;
      // line-height: 20px;
      // font-size: 14px;
      // font-weight: 400;
      font-size: 18px;
      color: #797979;
      font-family: 方正汉真广标简体;
      margin-top: 1px;
    }
  }
}
.page {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding: 8px 0;
  border: 1px solid #eee;
}
.first_footer {
  width: 100%;
}
::v-deep.el-carousel__arrow {
  i {
    color: #fff;
  }
}
::v-deep.el-carousel__indicators--horizontal {
  bottom: 0;
  right: 20px;
  left: 330px;
  transform: translateX(0) !important;
}
::v-deep.el-carousel__button {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}
</style>

<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-07-07 14:37:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\otherPage\newCenter\components\newCenterDetil.vue
-->
<template>
  <div class="inner_container">
    <div class="container">
      <title-images :imgObj="myImageObj" />
      <div class="inner_content">
        <div class="content_box">
          <h1 style="font-size: 27px; margin-top: 30px">
            {{ newDetail.newCenterTitle }}
          </h1>
          <p
            style="
              font-weight: 400;
              margin: 6px 0 30px 0;
              font-size: 20px;
              color: #666;
              font-family: MFLangQian_Noncommercial-Regular;
            "
          >
            {{ newDetail.createTime.substring(0, 11) }}
          </p>
        </div>
        <div class="content_box" v-html="newDetail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import titleImages from "@/components/titleImages";
import IMGS from "@/assets/images/state_1.jpg";
export default {
  name: "Home",
  components: { titleImages },
  data() {
    return {
      myImageObj: {
        url: IMGS,
        width: "100%",
        height: "100%",
      },
      myImageObj1: {
        url: IMGS,
        width: "100%",
        height: "100%",
      },
    };
  },
  computed: {
    locale() {
      return localStorage.getItem("language") || "zh";
    },
    newDetail() {
      const { id } = this.$route.query;
      let index;
      const locale = localStorage.getItem("language") || "zh";
      let newList = JSON.parse(sessionStorage.getItem("newList")) || [];
      if (newList && newList.length) {
        index = newList.findIndex((item) => {
          return id == item.id;
        });
      }
      switch (locale) {
        case "zh":
          return newList[index] || {};
        case "en":
          const infoEn = newList[index].infoEn;
          // if (infoEn) {
          //   return JSON.parse(newList[index].infoEn) || {};
          // } else {
            return newList[index] || {};
          // }

        default:
          return newList[index] || {};
      }
    },
    detilContent() {
      return this.$route.query.item;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
.content_box {
  // width: 880px;
  // margin: 0 auto;
  display: block;
  p {
    span {
      font-size: 13px !important;
      font {
        font-size: 13px !important;
      }
    }
  }
  .title {
    width: 880px;
    font-size: 48px;
    font-weight: bold;
    color: #222222;
    text-align: center;
  }
  .date {
    text-align: center;
    margin: 30px 0 55px 0;
    color: #999;
  }
  .txt {
    width: 880px;
    height: 73px;
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    font-weight: 400;
    color: #222222;
    margin: 40px 0;
  }
  .text1 {
    width: 880px;
    height: 185px;
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    font-weight: 400;
    color: #222222;
    margin: 40px 0;
  }
}

@media (max-width: 768px) {
  .content_box {
    width: 100%;
    ::v-deep.w-detail {
      width: 100%;
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
</style>

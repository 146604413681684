<!--
 * @Author: your name
 * @Date: 2021-07-01 09:17:29
 * @LastEditTime: 2021-07-16 16:14:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\otherPage\concactUs\index.vue
-->
<template>
  <div class="inner_container">
    <div class="container">
      <img
        class="us_banner"
        src="../../../assets/images/us_未标题-2.jpg"
        alt=""
      />
      <div class="us_containner">
        <div class="inner_content inner_content_us">
          <p class="conact_us_title">{{ $t("home.contact_us") }}</p>
          <div class="text_box">
            <div class="right_box right_box_us">
              <div class="content_us">
                <div class="content_txt">
                  <img src="../../../assets/images/us_未标题-3.jpg" alt="" />
                  <div class="concact_item">
                    <div>{{ $t("home.address") }}</div>
                    <span>{{ $t("home.company_addr") }}</span>
                  </div>
                </div>
                <div class="content_txt">
                  <!-- <i class="el-icon-phone icon"></i> -->
                  <img src="../../../assets/images/us_未标题-4.jpg" alt="" />
                  <div class="concact_item">
                    <div>{{ $t("home.telephone") }}</div>
                    <span>0755-2309 0656</span>
                  </div>
                </div>
                <div class="content_txt content_txt_email">
                  <img src="../../../assets/images/us_未标题-5.jpg" alt="" />
                  <div class="concact_item">
                    <div>{{ $t("home.mail") }}</div>
                    <span>Headoffice@goodchains.com</span>
                  </div>
                </div>
              </div>
              <div class="images_us">
                <div id="skyRoadMap" class="map_wrap"></div>
                <!-- <img src="../../../assets/images/us_concact.jpg" alt="" /> -->
              </div>
            </div>
          </div>
          <div class="company_list">
            <ul>
              <li v-for="(item, index) in branchList" :key="index">
                <div class="company_name">
                  <div class="bg_company_arrow">
                    {{ $t("home.branch_offices") }}
                  </div>
                  <span>{{ item.name }}</span>
                </div>
                <div class="flex_container">
                  <div class="flex_left">
                    <div class="company_address">
                      {{ $t("home.address") }}：{{ item.address }}
                    </div>
                    <div class="company_phone" v-if="item.phone">
                      {{ $t("home.telephone") }}：{{ item.phone }}
                    </div>
                    <div class="company_post">
                      {{ $t("home.postcode") }}：{{ item.postCode }}
                    </div>
                    <div class="company_post" v-if="item.businessScope">
                      {{ $t("home.business_scope") }}：{{ item.businessScope }}
                    </div>
                  </div>
                  <img v-if="item.imgUrl" :src="item.imgUrl" alt="" />
                </div>
              </li>
            </ul>
            <div class="form">
              <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-position="left"
                label-width="auto"
              >
                <div class="flex justify_content align_center title">
                  {{ $t("home.contact_learn_more") }}
                </div>
                <div class="flex justify_content align_center sub_title">
                  {{ $t("home.fill_form_get_more") }}
                </div>
                <el-row
                  v-if="locale === 'zh'"
                  :gutter="20"
                  type="flex"
                  justify="space-between"
                >
                  <el-col :span="8">
                    <el-form-item label="姓名" prop="name">
                      <el-input
                        v-model="form.name"
                        placeholder="姓名"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="手机号" prop="phone">
                      <el-input
                        v-model="form.phone"
                        placeholder="手机号"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div v-if="locale === 'en'">
                  <el-row :gutter="20" type="flex" justify="space-between">
                    <el-col :span="8">
                      <el-form-item label="FirstName" prop="firstName">
                        <el-input
                          v-model="form.firstName"
                          placeholder="FirstName"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="LastName" prop="lastName">
                        <el-input
                          v-model="form.lastName"
                          placeholder="LastName"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="Email" prop="email">
                        <el-input
                          v-model="form.email"
                          placeholder="Email"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item :label="$t('home.remark')" prop="remark">
                      <el-input
                        v-model="form.remark"
                        :placeholder="$t('home.remark')"
                        type="textarea"
                        :rows="3"
                        resize="none"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="flex justify_content align_center">
                  <el-button
                    :loading="subLoading"
                    class="btn"
                    @click="handleSubmit"
                    >{{ $t("home.contact_learn_more") }}</el-button
                  >
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setContactInfo } from "@/api/website/index.js";
export default {
  name: "Home",
  data() {
    return {
      subLoading: false,
      isPlatTplePc: true,
      map: null,
      form: {
        name: "",
        phone: "",
        firstName: "",
        lastName: "",
        email: "",
        remark: "",
      },
      //分公司列表
      branchList: [
        {
          name: this.$t("home.gz_airport_rice"),
          address: this.$t("home.gz_airport_addr"),
          phone: "020-86972941",
          postCode: "510800",
        },
        {
          name: this.$t("home.gz_rice"),
          address: this.$t("home.gz_rice_addr"),
          phone: "020-8676 0113",
          postCode: "510800",
        },
        {
          name: this.$t("home.sc_zf"),
          address: this.$t("home.sc_zf_addr"),
          phone: "028-85155125",
          postCode: "610000",
          imgUrl: require("@/assets/images/us_sczfgp.png"),
        },
        {
          name: this.$t("home.sc_ct"),
          address: this.$t("home.sc_ct_addr"),
          phone: "028-83287332",
          postCode: "610000",
          imgUrl: require("@/assets/images/us_scctnx.png"),
        },
        {
          name: this.$t("home.gx_xyt"),
          address: this.$t("home.gx_xyt_addr"),
          phone: "0755-82543109；13925288667",
          postCode: "538100",
        },
        {
          name: this.$t("home.sc_rice"),
          address: this.$t("home.sc_nx_addr"),
          phone: "028-85155125",
          postCode: "610300",
        },
        {
          name: this.$t("home.gz_rice_international"),
          address: this.$t("home.gz_rice_international_addr"),
          phone: "020-82515793",
          postCode: "510800",
          businessScope: this.$t("home.gz_micang_internation"),
        },
        {
          name: this.$t("home.xg_mc"),
          address: this.$t("home.xg_mc_addr"),
          postCode: "999077",
          businessScope: this.$t("home.xg_mc_bussiness"),
        },
        {
          name: this.$t("home.gz_xb"),
          address: this.$t("home.gz_xb_addr"),
          phone: "",
          postCode: "511458",
        },
        {
          name: this.$t("home.sz_micang_company"),
          address: this.$t("home.sz_micang_company_addr"),
          postCode: "518000",
          businessScope: this.$t("home.sz_micang_company_bussiness"),
        },
        {
          name: this.$t("home.sc_micang_nx"),
          address: this.$t("home.sc_micang_nx_addr"),
          postCode: "610200",
          businessScope: this.$t("home.sc_micang_nx_bussiness"),
        },
        {
          name: this.$t("home.sc_micang_goodchains"),
          address: this.$t("home.sc_micang_goodchains_addr"),
          postCode: "610200",
          businessScope: this.$t("home.sc_micang_goodchains_bussiness"),
        },
        {
          name: this.$t("home.fs_mc"),
          address: this.$t("home.fs_mc_addr"),
          postCode: "528000",
          businessScope: this.$t("home.fs_mc_busssiness"),
        },
      ],
    };
  },
  computed: {
    locale() {
      return localStorage.getItem("language") || "zh";
    },
    rules() {
      //根据不同语言使用不同规则
      let rules;
      switch (this.locale) {
        case "en":
          rules = {
            firstName: [
              { required: true, message: "Please enter your first name" },
            ],
            lastName: [
              { required: true, message: "please enter your last name" },
            ],
            email: [{ required: true, message: "please enter your email" }],
            remark: [{ required: true, message: "Please enter a note" }],
          };
          break;
        case "zh":
          rules = {
            phone: [{ required: true, message: "请输入手机号" }],
            name: [{ required: true, message: "请输入姓名" }],
            remark: [{ required: true, message: "请输入备注" }],
          };
          break;
        default:
          rules = {};
          break;
      }
      return rules;
    },
  },
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
    });
  },
  methods: {
    //清初始化表单
    initForm() {
      this.$refs.form.resetFields()
      this.form = {
        name: "",
        phone: "",
        firstName: "",
        lastName: "",
        email: "",
        remark: "",
      };
    },
    //提交用户信息
    async setContactInfo() {
      const params = await this.getRequestParams();
      try {
        this.subLoading = true;
        await setContactInfo(params);
        this.subLoading = false;
        this.initForm();
        this.$message.success(this.$t('home.submit_suc'))
      } catch (e) {
        this.subLoading = false;
        this.$message.error(this.$t('home.submit_err'))
        this.initForm();
        throw new Error(e);
      }
    },
    //初始化地图
    initMap() {
      let zoom = 4;
      this.map = new T.Map("skyRoadMap");
      this.map.centerAndZoom(new T.LngLat(108.95, 34.27), zoom);
      this.$nextTick(() => {
        this.addOverlay();
      });
    },
    //打开对应的marker信息窗口给
    openInfo(name, e) {
      const point = e.lnglat;
      const infoWindow = new T.InfoWindow(name, {
        offset: new T.Point(0, -30),
      });
      this.map.openInfoWindow(infoWindow, point);
    },
    // 添加覆盖物
    addOverlay() {
      const points = [
        {
          lng: "113.80233",
          lat: "22.67865",
          name: "深圳米仓供应链有限公司",
          addr: "",
        },
        {
          lng: "113.41073",
          lat: "23.3851",
          name: "广州空港米仓供应链有限公司",
          addr: "广州市白云区钟落潭镇善信路10号105室自编6号(空港白云)",
        },
        {
          lng: "113.238734",
          lat: "23.35625",
          name: "广州米仓供应链管理有限公司",
          addr: "广州市花都区绿港三街1号广州空港中心D栋404房自编59室(空港花都)",
        },
        {
          lng: "104.06685",
          lat: "30.618846",
          name: "四川中发工品科技发展有限公司",
          addr: "成都市武侯区航空路1号1栋21层",
        },
        {
          lng: "104.07982",
          lat: "30.792970",
          name: "四川成泰南向国际贸易有限公司",
          addr: "成都市金牛区聚霞路1号成都国际贸易港1号楼13楼YS01-1304-6",
        },
        {
          lng: "107.96536",
          lat: "21.54296",
          name: "广西兴云通国际物流管理有限公司",
          addr: "中国东兴--越南芒街跨境经济合作区建设部指挥办公室",
        },
        {
          lng: "104.058003",
          lat: "30.543650",
          name: "四川米仓南向供应链管理有限公司",
          addr: "中国(四川)自由贸易试验区成都市青白江区香岛大道1533号成都国际铁路港综合保税区1栋标准化厂房3层2号分区内",
        },
        {
          lng: "114.158479",
          lat: "22.247752",
          name: "香港米仓供应链管理有限公司",
          addr: "金鐘金鐘道89號 力寶中心第一座10樓1003室, 香港",
        },
        {
          lng: "113.087287",
          lat: "23.029736",
          name: "佛山米仓供应链管理有限公司",
          addr: "佛山市禅城区五峰三路口岸大楼204a",
        },
      ];
      if (points && points.length) {
        points.forEach((item) => {
          const { lng, lat, name } = item;
          const marker = new T.Marker(new T.LngLat(lng, lat)); // 创建标注
          this.map.addOverLay(marker);
          this.handleMarkerClick(name, marker);
        });
      }
    },
    //监听marker点击事件
    handleMarkerClick(name, marker) {
      let _this = this;
      marker.addEventListener("click", function (e) {
        _this.openInfo(name, e);
      });
    },
    // 获取请求参数
    getRequestParams() {
      const params = {
        ...this.form,
      };
      switch (this.locale) {
        case "en":
          params["name"] = `${params["firstName"]} ${params["lastName"]}`;
          delete params["firstName"];
          delete params["lastName"];
          delete params["phone"];
          break;
        case "zh":
          delete params["firstName"];
          delete params["lastName"];
          delete params["email"];
          break;
        default:
          delete params["firstName"];
          delete params["lastName"];
          break;
      }
      return params;
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.setContactInfo();
          return;
        } else {
          return;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
.inner_container {
  .us_banner {
    width: 100%;
    height: auto;
  }
  .us_containner {
    background-color: #f1f4f6;
    min-height: 400px;
  }
}
.text_box {
  padding-bottom: 0 !important;
}
.company_list {
  margin-top: 63px;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin-bottom: 62px;
      width: 585px;
      .company_name {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url("../../../assets/images/company_backgroud.png") center
          no-repeat;
        background-size: 100%;
        // background: company_backgroud;
        .bg_company_arrow {
          width: 151px;
          height: 50px;
          line-height: 52px;
          text-align: center;
          font-size: 24px;
          padding-right: 8px;
          color: #fff;
          background: url("../../../assets/images/us_company_ar.png") center
            no-repeat;
          background-size: 100%;
        }
        // img {
        //   width: 151px;
        //   height: 50px;
        // }
        span {
          flex: 1;
          margin-left: 30px;
          color: #1d1d1d;
          font-size: 24px;
        }
      }
      .company_address {
        margin-top: 19px;
        color: #1d1d1d;
        font-size: 15px;
        padding-left: 20px;
      }
      .company_phone,
      .company_post {
        padding-left: 20px;
        margin-top: 16px;
        color: #1d1d1d;
        font-size: 15px;
      }
      .flex_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 79px;
          height: 79px;
          margin: 12px 10px 0 0;
        }
      }
    }
  }
}
.map_wrap {
  width: 100%;
  height: 400px;
}
.form {
  width: 100%;
  margin: 10px 0;
  .btn {
    background-color: #f47d54;
    & ::v-deep span {
      color: #fff !important;
    }
  }
  .flex {
    display: flex;
  }
  .align_center {
    align-items: center;
  }
  .justify_content {
    justify-content: center;
  }
  .justify_space_between {
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .sub_title {
    font-size: 12px;
    margin-bottom: 15px;
  }
}
</style>

<template>
  <div :class="isPlatTplePc ? 'header' : 'app_header'">
    <div class="pc_header">
      <div class="header_nav">
        <div class="logo" @click="goHome">
          <div class="image">
            <img src="../assets/images/logo.jpg" width="100%" height="100%" />
          </div>
        </div>
        <div class="nav">
          <ul class="nav_lists">
            <li
              class="nav_li"
              v-for="item in navList"
              :key="item.link"
              @click="handleSelect(item)"
              @mouseover="mouseOver(item)"
              @mouseleave="mouseLeave(item)"
            >
              <a
                href="javascript:;"
                :class="activeIndex == item.link ? 'active' : ''"
                >{{ item.name }}</a
              >
              <div
                class="aboutNotive_nav"
                v-if="isShowAbout && item.link == '/aboutRiceStroe'"
              >
                <ul class="nav_items">
                  <li class="li_first">{{ $t("home.about_rice_barn") }}</li>
                  <li
                    @mouseover="mouseoverAboutBrief"
                    @mouseleave="mouseleaveAboutBrief"
                  >
                    {{ $t("home.enterprise_overview")
                    }}<i class="el-icon-arrow-down"></i>
                    <div
                      class="company_brief"
                      v-if="isShowAboutBrief && item.link == '/aboutRiceStroe'"
                    >
                      <ul>
                        <li
                          v-for="(item, index) in aboutNavList"
                          :key="index"
                          @click="goAboutNotive(item)"
                        >
                          <a href="javascript:;">{{ item.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li
                    @mouseover="isShowAboutFrame = true"
                    @mouseleave="isShowAboutFrame = false"
                  >
                    {{ $t("home.branch_architecture")
                    }}<i class="el-icon-arrow-down"></i>
                    <div
                      class="company_brief company_frame"
                      v-if="isShowAboutFrame && item.link == '/aboutRiceStroe'"
                    >
                      <ul>
                        <li
                          v-for="(item, index) in aboutFrameList"
                          :key="index"
                          @click="goAboutNotive(item)"
                        >
                          <a href="javascript:;">{{ item.name }}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="aboutNotive_nav"
                v-if="isShowBusiness && item.link == '/businessServe'"
              >
                <ul class="nav_items nav_business">
                  <li class="li_first">{{ $t("home.main_business") }}</li>
                  <li @click="goBusiness(1)">
                    {{ $t("home.rice_industrial_platform") }}
                  </li>
                  <li @click="goBusiness(2)">
                    {{ $t("home.rice_popcorn_platform") }}
                  </li>
                  <li @click="goBusiness(3)">
                    {{ $t("home.rice_outside_platform") }}
                  </li>
                  <li @click="goBusiness(4)">
                    {{ $t("home.rice_oversea_store") }}
                  </li>
                  <li @click="goBusiness(5)">
                    {{$t('home.product_case')}}
                  </li>
                </ul>
              </div>
            </li>
            <!-- <li>
              <el-dropdown @command="setLang">
                <span class="el-dropdown-link">
                  {{ lang.label
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in localeList"
                    :key="index"
                    :command="item.name"
                    :disabled="lang.name === item.name"
                    >{{ item.label }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isShowAbout: false,
      isShowAboutBrief: false,
      isShowAboutFrame: false,
      activeIndex: "/index",
      drawer: false,
      //语言类型列表
      localeList: [
        {
          name: "en",
          label: "EngLish",
          value: 1,
        },
        {
          name: "zh",
          label: "中文",
          value: 2,
        },
      ],
      navList: [
        {
          name: this.$t("home.home_page"),
          link: "/index",
        },
        {
          name: this.$t("home.about_rice_barn"),
          link: "/aboutRiceStroe",
        },
        {
          name: this.$t("home.business_and_service"),
          link: "/businessServe",
        },
        {
          name: this.$t("home.dynamic"),
          link: "/newCenter",
        },
        {
          name: this.$t("home.join_us"),
          link: "/staffRecruit",
        },
        {
          name: this.$t("home.contact_us"),
          link: "/concactUs",
        },
      ],
      aboutNavList: [
        {
          name: this.$t("home.rice_introduction"),
          link: "g_1",
        },
        {
          name: this.$t("home.culture_and_vision"),
          link: "g_2",
        },
        {
          name: this.$t("home.social_responsibility"),
          link: "g_3",
        },
      ],
      aboutFrameList: [
        {
          name: this.$t("home.gz_airport_rice"),
          link: "g_4",
        },
        {
          name: this.$t("home.sc_ct"),
          link: "g_5",
        },
        {
          name: this.$t("home.sc_zf"),
          link: "g_6",
        },
        {
          name: this.$t("home.gx_xyt"),
          link: "g_7",
        },
        {
          name: this.$t("home.gz_rice"),
          link: "g_8",
        },
        {
          name: this.$t("home.sc_rice"),
          link: "g_9",
        },
        {
          name: this.$t("home.gz_xb"),
          link: "g_10",
        },
        {
          name: this.$t("home.gz_rice_international"),
          link: "g_11",
        },
        {
          name: this.$t("home.fs_mc"),
          link: "g_12",
        },
      ],
      isPlatTplePc: true,
      isShowBusiness: false,
    };
  },
  computed: {
    lang() {
      const locale = localStorage.getItem("language") || "en";
      const obj = this.localeList.find((item) => item.name === locale);
      return obj;
    },
  },
  watch: {
    $route(to) {
      this.activeIndex = to.path;
    },
  },
  created() {
    this.activeIndex = this.$route.path;

    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },

  methods: {
    //设置语言
    setLang(command) {
      this.$i18n.locale = command;
      const loading=this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      loading
      this.$message.success("Switch Language Success");
      localStorage.setItem("language", command);
      window.location.reload(true);
    },
    goHome() {
      this.$router.push("/index");
      this.activeIndex = "/index";
    },
    mouseOver(item) {
      if (
        item.link == "/aboutRiceStroe" &&
        this.$route.path != "/aboutRiceStroe"
      ) {
        this.isShowAbout = true;
      } else if (
        item.link == "/businessServe" &&
        this.$route.path != "/businessServe"
      ) {
        this.isShowBusiness = true;
      }
    },
    mouseLeave(item) {
      if (item.link == "/aboutRiceStroe") {
        this.isShowAbout = false;
      } else if (item.link == "/businessServe") {
        this.isShowBusiness = false;
      }
    },
    mouseoverAboutBrief() {
      this.isShowAboutBrief = true;
    },
    mouseleaveAboutBrief() {
      this.isShowAboutBrief = false;
    },
    goAboutNotive(item) {
      console.log(item.link);
      this.activeIndex = "/aboutRiceStroe";
      this.$router.push({
        path: "/aboutRiceStroe",
        query: {
          gId: item.link,
        },
      });
    },
    goBusiness(type) {
      this.activeIndex = "/businessServe";
      this.$router.push({
        path: "/businessServe",
        query: {
          tabId: type,
        },
      });
    },
    handleSelect(item) {
      this.activeIndex = item.link;
      this.$router.push(item.link);
      this.drawer = false;
    },
    // clickDrawer() {
    //   this.drawer = !this.drawer;
    // }
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 80px;
  width: 100%;
  background: #fff;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  .pc_header {
    width: 1275px;
    height: 100%;
    margin: 0 auto;
  }
  .header_nav {
    display: flex;
  }
  .aboutNotive_nav {
    position: fixed;
    width: 100%;
    top: 80px;
    left: 0;
    background: #fff;
    border-top: 1px solid #eee;
    .nav_items {
      width: 1000px;
      margin: 0 auto;
      // padding-left: 68px;
      display: flex;
      align-items: center;
      li {
        font-size: 14px;
        color: #515151;
        padding: 0 40px;
        cursor: pointer;
        position: relative;
        height: 36px;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 36px;
        white-space: nowrap;
        &:hover {
          color: #d90000;
          i {
            color: #d90000;
          }
        }
        i {
          margin-left: 6px;
        }
        .company_brief {
          // width: 112px;
          position: absolute;
          background: #fff;
          left: 27px;
          top: 36px;
          padding: 4px 0;
          border-top: 1px solid #d90000;
          ul {
            li {
              padding: 7px 30px 7px 16px;
              line-height: 28px;
              &:hover {
                a {
                  color: #d90000;
                }
              }
              a {
                font-size: 12px;
                color: #333;
              }
            }
          }
        }
        .company_frame {
          // width: 228px;
          padding: 4px 0;
        }
      }
      .li_first {
        border-right: 1px solid #ccc;
        margin: 3px 0;
        padding: 0px 40px;
      }
    }
    .nav_business {
      li {
        padding: 4px 30px;
        height: auto !important;
      }
    }
  }
}

.app_header {
  // height: 44px;
  // width: 100%;
  // padding: 0 16px;
  .app_inner_header {
    height: 44px;
    width: 100%;
    // padding: 0 16px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 999;
  }
  .app_header_content {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  .image_app {
    width: 140px;
    height: 100%;
    line-height: 44px;
    margin-left: 16px;

    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .show_more_nav {
    position: relative;
    width: 40px;
    height: 44px;
    line-height: 44px;
    text-align: right;
    margin-right: 16px;
    ::v-deep.el-icon-s-operation {
      font-size: 30px;
      vertical-align: middle;
    }
  }
}
.header {
  margin: 0 auto;
  .el-col {
    height: 44px;
  }
  .logo {
    width: 310px;
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .image {
      width: 194px;
      height: 80px;
    }
  }
  .nav {
    
    display: flex;
    align-items: center;
    margin-left: 28px;
    .nav_lists {
      height: 80px;
      display: flex;
      align-items:center;
      .nav_li:first-child {
        margin-right: 55px;
      }
      .nav_li {
        margin-right: 55px;
        height: 80px;
        line-height: 80px;
        &:hover {
          > a {
            color: #d90000;
          }
        }
        a {
          height: 17px;
          font-size: 20px;
          font-family: HarmonyOS_Sans_SC;
          font-weight: 400;
          color: #515151;
        }
        .active {
          color: #d90000;
        }
      }
    }
  }
}
.app_nav {
  width: 100%;
  padding: 30px;
  ul {
    li {
      width: 100%;
      text-align: center;
      padding: 10px;
      a {
        font-size: 16px;
      }
      .active {
        color: #d90000;
      }
    }
  }
}
.app_nav_list {
  width: 100%;
  position: absolute;
  left: 0;
  top: -500px;
  height: 310px;
  background: #fff;
  transition: top linear 0.3s;
  z-index: 9;
  // opacity: 0;
}
.active_nav {
  top: 44px;
  // opacity: 1;
}
.el-dropdown-link {
  cursor: pointer;
}
// .app_header {
//   .el-col {
//     height: 44px;
//   }
// }
</style>
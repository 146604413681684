<template>
  <div class="bottom">
    <div class="bottom-top">
      <div class="bottom-left">
        <div class="bottom-content">
          <div class="bottom-nav" :class="isPlatTplePc ? '' : 'isPcPlat'">
            <ul>
              <li class="li_line li_first" @click="openBtn(1)">
                <a href="javascript:;">{{$t('home.legal_statement')}}</a>
              </li>
              <li class="li_line" @click="openBtn(2)">
                <a href="javascript:;">{{$t('home.website_map')}}</a>
              </li>
              <li class="li_line">
                <!-- <a href="javascript:;"></a> -->
                <router-link to="/concactUs">{{$t('home.contact_us')}}</router-link>
              </li>
              <!--              <li class="li_line">-->
              <!--                &lt;!&ndash; <a href="javascript:;"></a> &ndash;&gt;-->
              <!--                <router-link to="/concactUs">联系我们</router-link>-->
              <!--              </li>-->
              <li class="li_last">
                <router-link to="/dispatchSearch">{{$t('home.waybill_query')}}</router-link>
              </li>
              <li>
                <div class="bottom-footer">
                  <span style="width:20vw;text-align:left">Copyright&copy;{{$t('home.micang_group')}}</span>
                  <a target="_blank" href="https://beian.miit.gov.cn"
                    >粤ICP备19052813号</a
                  >
                  <span style="margin-left: 10px">
                    <a
                      target="_blank"
                      href="https://www.beian.gov.cn/portal/registerSystemInfo"
                      >粤公网安备 44030402003651号</a
                    >
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="bottom-text">
            <div class="text_two">
              <span> {{$t('home.company_addr')}}</span>
            </div>
            <div class="text">{{$t('home.postcode')}} : 518000 {{$t('home.telephone')}} : 0755-23090656</div>
          </div>
          <!-- <div class="bottom-text branch_company">
            <div class="text_two">
              四川中发工品科技发展有限公司
            </div>
            <div class="text">
              四川省成都市武侯区航空路1号国航世纪中心A座21楼
            </div>
            <div class="text">
              邮编 : 510800 电话 : 020-86972941
            </div>
          </div>
          <div class="bottom-text branch_company">
            <div class="text_two">
              广州空港米仓供应链有限公司
            </div>
            <div class="text">
              广州市花都区雅瑶东路绿港三街3号广州空港中心A栋501室
            </div>
            <div class="text">
              邮编 : 510800 电话 : 020-86972941
            </div>
          </div>
          <div class="bottom-text branch_company">
            <div class="text_two">
              四川成泰南向国际贸易有限公司
            </div>
            <div class="text">
              成都市武侯区交子大道177号中海国际中心A座1509
            </div>
            <div class="text">
              邮编 : 610000 电话 : 028-83287332
            </div>
          </div>
          <div class="bottom-text branch_company">
            <div class="text_two">
              上海米之谷有限公司
            </div>
            <div class="text">上海市闵行区七宝镇七莘路1839号财富108广场南楼9层908室</div>
            <div class="text">
              邮编 : 200233 电话 : 0755-8254 3109
            </div>
          </div> -->
        </div>
      </div>

      <div class="bottom-right">
        <div class="weixin_code">
          <img
            src="../assets/images/wxewm.png"
            alt=""
            width="100%;"
            height="100%;"
          />
        </div>
        <!-- <div class="weixin_code">
          <img
            src="../assets/images/wexin_ewm2.png"
            alt=""
            width="100%;"
            height="100%;"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bottom",
  data() {
    return {
      activeIndex: "/index",
      navList: [
        {
          name: "首页",
          link: "/index",
        },
        {
          name: "关于米仓",
          link: "/aboutRiceStroe",
        },
        // {
        //   name: "核心业务",
        //   link: "/coreBusiness"
        // },
        {
          name: "米仓动态",
          link: "/newCenter",
        },
        {
          name: "加入我们",
          link: "/staffRecruit",
        },
        {
          name: "联系我们",
          link: "/concactUs",
        },
      ],
      isPlatTplePc: true,
      isShowWweixinCode: false,
    };
  },
  created() {
    this.activeIndex = this.$route.path;
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },

  methods: {
    goHome() {
      this.$router.push("/");
    },
    handleSelect(item) {
      this.activeIndex = item.link;
      this.$router.push(item.link);
    },
    // 移入
    mouseOverCode() {
      this.isShowWweixinCode = true;
    },
    // 移出
    mouseLeaveCode() {
      this.isShowWweixinCode = false;
    },
    openBtn(type) {
      this.$emit("openDrawer", type);
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 10px;
  background: #f0f2f5;
  .bottom-top {
    width: 1275px;
    padding-top: 24px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 17px;
    .bottom-left {
      display: flex;
      .bottom-content {
        // margin-left: 40px;
        .bottom-nav {
          ul {
            display: flex;
            li {
              padding: 0 10px;
              position: relative;
              a {
                font-size: 14px;
                color: #5f5f5f;
              }
            }
            .li_line {
              &::after {
                content: " ";
                width: 1px;
                height: 10px;
                background: #5f5f5f;
                position: absolute;
                top: 4px;
                right: 0;
                border-radius: 0.1875rem;
              }
            }
            .li_first {
              padding-left: 0;
            }
          }
        }
        .bottom-text {
          margin-top: 8px;
          color: #5f5f5f;
          img {
            width: 15px;
            vertical-align: middle;
          }
          .text_two {
            margin-top: 6px;
            line-height: 22px;
            span {
              font-size: 14px;
              color: #5f5f5f;
            }
          }
          .text {
            font-size: 14px;
            color: #5f5f5f;
          }
        }
      }
    }
    .bottom-right {
      text-align: right;
      .weixin_code {
        width: 55px;
        height: 55px;
        background: #ffffff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px #eee;
        margin-bottom: 20px;
      }
      span {
        color: #333333;
        font-size: 4px;
        margin-right: 12px;
      }
      img {
        // width: 31px;
        // height: 25px;
        vertical-align: middle;
      }
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #515151;
    span {
      margin-right: 10px;
      color: #515151;
    }
    a {
      margin-left: 10px;
      color: #d31223 !important;
    }
  }
  .active {
    color: #d90000;
  }
}
.isPcPlat {
  display: none;
}
.branch_company {
  margin-top: 16px !important;
  .text_two {
    font-size: 14px;
    color: #5f5f5f;
  }
}
@media (max-width: 768px) {
  .bottom {
    position: relative;
    padding: 15px 10px;
    .bottom-top {
      width: 100%;
      display: block;
      padding-bottom: 8px;
      .bottom-left {
        display: flex;
        .bottom-logo {
          .logo {
            width: 36px;
            height: auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .bottom-content {
          // margin-left: 16px;
          .bottom-nav {
            ul {
              display: flex;
              li {
                margin-right: 20px;
                a {
                  font-size: 14px;
                  font-size: #5f5f5f;
                }
              }
            }
          }
          .bottom-text {
            margin-top: 0;
            color: #333;
            img {
              width: 15px;
              vertical-align: middle;
            }
            .text {
              font-size: 11px;
            }
            .text_two {
              margin-top: 6px;
              font-size: 11px;
              display: flex;
              img {
                height: 16px;
              }
              span {
                font-size: 11px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .bottom-right {
        position: absolute;
        top: 10px;
        right: 13px;
        margin-top: 0;
        span {
          color: #333333;
          font-size: 4px;
          margin-right: 12px;
        }
        img {
          width: 19px;
          height: auto;
          vertical-align: middle;
        }
      }
    }
    .bottom-footer {
      width: 100%;
      margin: 12px auto 0;
      span {
        font-size: 11px;
        margin-right: 6px;
      }
      a {
        font-size: 11px;
        margin-left: 6px;
      }
    }
  }
}
</style>
<template>
  <div class="m-timeline-area">
    <div class="m-timeline">
      <div
        :class="['m-timeline-item', { last: index === timeline.length - 1 }]"
        v-for="(item, index) in timeline"
        :key="index"
      >
        <div class="u-tail"></div>
        <div class="u-dot"></div>
        <div class="u-content">
          <p>{{ item.statusName }}</p>
          <p>{{ item.actualTime }} {{ item.statusInfo }}<span v-if="item.remark">({{ item.remark }})</span></p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "timeLine",
  props: {
    timeline: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {
    // console.log(this.timeline);
  },
};
</script>

<style lang="less" scoped>
@blue: #1890ff;
@green: #52c41a;
@red: #f5222d;
@gray: rgba(0, 0, 0, 0.25);
.m-timeline-area {
  width: 50vw;
  margin: 30px auto;
  // margin-left: 425px;
  background: white;
  .m-timeline {
    .m-timeline-item {
      position: relative;
      padding-bottom: 30px;
      .u-tail {
        position: absolute;
        top: 10px;
        left: 5px;
        height: calc(100% - 10px);
        //border-left: 2px solid black; // 实线
        // border-left: 2px dotted #e8e8e8; // 点线
        border-left: 2px dashed #e8e8e8; // 虚线
      }
      .u-dot {
        position: absolute;
        width: 8px;
        height: 8px;
        border: 2px solid @blue;
        border-radius: 50%;
      }
      .u-content {
        position: relative;
        top: -6px;
        margin-left: 25px;
        word-break: break-word;
        line-height: 24px;
      }
    }
    .last {
      .u-tail {
        display: none;
      }
    }
  }
}
</style>
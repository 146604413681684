<template>
  <div class="inner_container">
    <div class="container">
      <div class="transport">
        <!-- <div class="transport-type">
          <el-select v-model="transport" clearable placeholder="请选择">
            <el-option
              v-for="item in transportList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <el-input v-model="dispatchCode" :placeholder="$t('home.track_no')" />
        <span style="margin-left: 20px">
          <el-button @click="getData">{{$t('home.query')}}</el-button>
        </span>
      </div>
      <div class="container-header">
        <el-radio v-model="radio" @change="changeRadio" :label="0"
          >{{$t('home.reservation_form')}}</el-radio
        >
        <el-radio v-model="radio" @change="changeRadio" :label="1"
          >{{$t('home.inlet')}}</el-radio
        >
        <el-radio v-model="radio" @change="changeRadio" :label="2"
          >{{$t('home.exits')}}</el-radio
        >
      </div>
      <div v-if="radio === 0 && list.length > 0">
        <reservation-time-line :timeline="list"></reservation-time-line>
      </div>
      <div v-if="radio !== 0">
        <div
          class="inner_content"
          style="margin-top: 30px"
          v-for="(item, index) in dispatchlineArr"
          :key="index"
        >
          <div class="content_title">
            <div>{{$t('home.track_no')}}：{{ item.dispatchCode }}</div>
            <div style="margin-top: 20px">{{$t('home.address')}}：{{ item.address }}</div>
          </div>
          <time-line v-if="item.point" :timeline="item.point" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reservationTimeLine from "./components/reservationTimeLine";
import TimeLine from "@/views/otherPage/dispatchSearch/timeLine";
import { getDispatchInfo, getReservationInfo } from "@/api/dispatchSearch";
import { formatDate } from "@/common/date";
import moment from "moment";
export default {
  name: "index",
  components: { TimeLine, reservationTimeLine },
  props: {},
  data() {
    return {
      transport: "",
      radio: 0,
      dispatchCode: "",
      total: 0,
      list: [],
      address: "",
      dispatchlineArr: [],
    };
  },
  computed: {
    orderId() {
      return this.$route.query.id;
    },
    isOperate() {
      return this.$route.query.noUpload ? true : false;
    },
  },
  mounted() {},
  methods: {
    //切换radio时候清空数据
    changeRadio() {
      this.list = [];
      this.dispatchlineArr = [];
    },
    getData() {
      if (this.radio === 0) {
        this.getReservationInfo();
        return;
      }
      this.getDispatchInfo();
    },
    async getReservationInfo() {
      if (!this.dispatchCode) {
        return;
      }
      // const timeLineArr=[]
      const { code, data } = await getReservationInfo(this.dispatchCode);
      if (code === 200 && data) {
        const { confirmationOrderStatusDtoList } = data;
        confirmationOrderStatusDtoList.map((item) => {
          item.actualTime = moment(item.actualTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          if (item.statusIndex === 1) {
            item.statusInfo = `${this.$t('home.in_store_before')}${data.qty || 0}${this.$t('home.item')}， ${this.$t('home.weights')}${data.grossWeight || 0}KG,${this.$t('home.volumetric')}${data.volume || 0}CBM`;
          }
        });
        this.list = confirmationOrderStatusDtoList;
        return;
      }
    },
    getDispatchInfo() {
      if (!this.dispatchCode) {
        return;
      }

      getDispatchInfo(this.dispatchCode).then((res) => {
        this.dispatchlineArr = [];
        if (res.data && res.data.list) {
          let list = res.data.list;
          for (const items in list) {
            var lineArr = [];
            let orderStatusList = list[items].orderStatusList;
            for (const item in orderStatusList) {
              let lineContent = {
                orderStatusName: orderStatusList[item].orderStatusName,
                name: orderStatusList[item].statusInfo,
                time: orderStatusList[item].actualUpdateTime
                  ? formatDate(
                      new Date(orderStatusList[item].actualUpdateTime),
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  : "",
              };
              lineArr.push(lineContent);
            }
            var address =
              list[items].originPlace + " ---> " + list[items].destinationPlace;
            this.dispatchlineArr.push({
              dispatchCode: res.data.orderNo,
              address: address,
              point: lineArr,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inner_content {
  width: 50vw;
  margin: 10px auto;
}
.container-header {
  width: 50vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px auto;
}
.content_title {
  width: 50vw;
  margin: 0 auto;
  text-align: left;
}
.transport {
  width: 50vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  .transport-type {
    margin-right: 10px;
  }
}
.waybill-tracking {
  .searchInput {
  }
  .content {
    .top-wrap {
      position: relative;
      .top {
        background: #f5f5f5;
        font-size: 12px;
        color: #333;
        padding: 8px 20px;
      }
      .btns {
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }

    .report-wrapper {
      background: #f5f5f5;
      margin-top: 10px;
      padding: 10px;

      .report-title {
        font-size: 14px;
        color: #333;
        font-weight: 500;
      }
      .report-item {
        font-size: 12px;
        color: #333;
        line-height: 25px;
      }
      .report-img {
        margin-left: 50px;
      }
    }

    .wrapper {
      position: relative;
      transition: all 0.5s ease-in-out;
      border-top: 10px solid #ffffff;

      .map-wrap {
        width: 100%;
        height: 500px;
        position: absolute;
        z-index: 1;

        .bm-view {
          width: 100%;
          height: 100%;
        }
      }
      .wrap-line {
        width: 100%;
        min-height: 510px;
        position: absolute;
        // 这个z-index要比左侧菜单栏和顶部Navbar高,不然图片放大的组件会被遮挡住
        z-index: 1002;
        margin-top: 80px;
        background: #fff;
        margin-bottom: 20px;
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
        transition: all 0.5s ease-in-out;

        &.opened {
          margin-top: 500px;
        }

        .order-info {
          background: #f5f5f5;
          font-size: 12px;
          color: #333;
          padding: 8px 110px;
          position: relative;
          p {
            margin: 6px 0;
          }
          .add-fence-node {
            position: absolute;
            right: 20px;
            top: calc(50% - 14px);
          }
        }
      }

      .order-track {
        margin-bottom: 30px;
      }

      .timeline {
        width: 100%;
        min-height: 80px;
        padding-left: 30px;
        position: relative;

        &:nth-of-type(1) {
          .top-line {
            visibility: hidden;
          }
        }
        &:nth-last-of-type(1) {
          .bottom-line {
            visibility: hidden;
          }
        }

        .timeline-name {
          width: 100px;
          text-align: right;
          font-size: 12px;
          color: #333;
          margin-right: 50px;

          &.active {
            color: #333;
          }
          &.unActive {
            color: #999;
          }
        }

        .timeline-icon {
          color: #999;
          height: 100%;
          padding: 0 10px;
          position: absolute;
          left: 140px;

          .top-line,
          .bottom-line {
            width: 1px;
            height: 50%;

            &.active {
              border-left: 1px solid #333;
            }
            &.unActive {
              border-left: 1px dashed #999;
            }
          }

          .svg-icon {
            width: 17px;
            height: 17px;
            position: absolute;
            top: calc(50% - 8px);

            &.active {
              color: #333;
            }
            &.unActive {
              color: #999;
            }
          }
        }

        .timeline-content {
          flex: 1;
          font-size: 12px;
          min-height: 80px;
          box-sizing: border-box;
          border-bottom: 1px solid #dcdfe6;
          .item {
            line-height: 28px;
            width: 400px;
            position: relative;
            padding-left: 20px;

            .timeout {
              position: absolute;
              left: -15px;
              top: calc(50% - 16px);
            }

            .time {
              white-space: nowrap;
              &:hover {
                color: #409eff;
                cursor: pointer;
              }

              &.disabled {
                &:hover {
                  color: #333333;
                  cursor: auto;
                }
              }
            }

            .info {
              max-width: 400px;
            }
            :nth-child(n) {
              margin-right: 20px;
            }
          }

          .remark {
            display: inline-block;
            width: 200px;
            margin: 10px 20px 10px 0;
          }
        }

        .upload-wrap {
          padding: 10px;
        }
      }
    }

    .view-box {
      display: inline-block;
      width: 200px;
      height: 0;
      margin-left: calc(50% - 100px);
      border-bottom: 25px solid #ffffff;
      border-right: 20px solid transparent;
      border-left: 20px solid transparent;
      transform: translateY(-25px);
      line-height: 25px;
      text-align: center;
      font-size: 12px;
      color: #409eff;
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div class="inner_container">
    <div class="container">
      <div v-if="isPlatTplePc">
        <title-images :imgObj="myImageObj" />
      </div>
      <div class="inner_content">
        <div class="staff_top">
          <div class="staff_swiper">
            <el-carousel :interval="5000" arrow="always">
              <div class="swiper_content" id="swiper_about">
                <el-carousel-item
                  v-for="(item, index) in staffDataList"
                  :key="index"
                >
                  <img :src="item.url" width="100%" height="100%" />
                </el-carousel-item>
              </div>
            </el-carousel>
          </div>
          <div class="company_info">
            <div class="item_name">{{ $t("home.talent_mc_yonekura") }}</div>
            <div class="company_des">
              {{ $t("home.tanlent_mc_yonekura_text_1") }}
            </div>
            <div class="company_des" style="margin-top: 20px">
              {{ $t("home.tanlent_mc_yonekura_text_2") }}
            </div>
            <a href="#anchorName" class="show_more"
              >{{ $t("home.learn_more") }} +</a
            >
          </div>
        </div>
        <div class="text_box" id="anchorName">
          <left-box
            :list="navList"
            :listTitle="$t('home.recruitment')"
            @tabBtn="tabBtn"
          />
          <div class="right_box">
            <div v-if="tabIndex != 3">
              <ul class="invite_items_ul">
                <div class="search_title">
                  <span></span>{{ $t("home.job_search") }}
                </div>
                <div class="search_div">
                  <el-input
                    @input="searchList"
                    :placeholder="$t('home.enter_job_keyword')"
                    v-model="keyWord"
                    class="input-with-select"
                  >
                    <el-button slot="append" icon="el-icon-search"></el-button>
                  </el-input>
                  <div class="filter_div">
                    <div class="filter_address">
                      <span>{{ $t("home.workplace") }}&nbsp;&nbsp;&nbsp;></span>
                      <ul>
                        <li
                          v-for="(item, index) in addressList"
                          :key="index"
                          @click="changeAddress(item)"
                          :class="addressIndex == item.id ? 'activeColor' : ''"
                        >
                          {{ item.text }}
                        </li>
                      </ul>
                    </div>
                    <div class="filter_address">
                      <span
                        >{{ $t("home.release_time") }}&nbsp;&nbsp;&nbsp;></span
                      >
                      <ul>
                        <li
                          v-for="(item, index) in releaseTimeList"
                          :key="index"
                          @click="changeRelease(item)"
                          :class="
                            releaseTimeIndex == item.id ? 'activeColor' : ''
                          "
                        >
                          {{ item.text }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <li
                  class="items_li"
                  v-for="(item, index) in tableData[pages.page]"
                  :key="index"
                >
                  <div class="invite_company">{{ item.companyName }}</div>
                  <div class="invite_position">
                    <span class="positon_name">{{ item.name }}</span>
                    <!-- <div class="positon_ask">{{item.yaoqiujianshu}}</div> -->
                    <div class="positon_ask">
                      <span class="span_first">{{ item.address }}</span
                      ><span>{{ item.workYear }}</span
                      ><span>{{ item.education }}</span
                      ><span class="span_last">{{ item.money }}</span>
                    </div>
                  </div>
                  <div class="invite_num">
                    {{ $t("home.recruit") }}{{ item.num
                    }}{{ $t("home.people") }}
                  </div>
                  <div class="invite_update_time">
                    {{ item.createTime | createformat() }}
                  </div>
                  <div @click="godetil(item)" class="apply_positon">
                    {{ $t("home.apply_for_position") }}
                  </div>
                </li>
              </ul>
              <div class="page">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="pages.total"
                  :hide-on-single-page="false"
                  @current-change="currentChange"
                >
                </el-pagination>
              </div>
            </div>
            <div v-else class="recruit_sm">
              <div class="zp_sm">
                <div class="recruit_title">
                  {{ $t("home.rice_warehouse_recruitment") }}
                </div>
                <div class="sm_contant_div">
                  <div class="recruit_des">
                    &nbsp;&nbsp;&nbsp;{{ $t("home.recruitment_text_1") }}
                  </div>
                  <ul>
                    <li>
                      &nbsp;&nbsp;&nbsp;1.{{ $t("home.recruitment_text_2")
                      }}<br />
                      {{ $t("home.recruitment_text_3") }}<br />
                      {{ $t("home.recruitment_text_4") }}
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;2.{{ $t("home.recruitment_text_5")
                      }}<br />
                      {{ $t("home.recruitment_text_6") }}
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;3.{{ $t("home.recruitment_text_7")
                      }}<br />{{ $t("home.recruitment_text_8") }};
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;4.{{ $t("home.recruitment_text_9")
                      }}<br />
                      {{ $t("home.recruitment_text_10") }};
                    </li>
                    <li>
                      &nbsp;&nbsp;&nbsp;5.{{ $t("home.recruitment_text_11")
                      }}<br />
                      {{ $t("home.recruitment_text_12") }}<br />
                      &nbsp;&nbsp;&nbsp;{{ $t("home.recruitment_text_13") }}!
                    </li>
                    <!-- <li></li> -->
                  </ul>
                </div>

                <div class="sm_bottom">
                  <div>{{ $t("home.micang_group") }}</div>
                  <div>{{ $t("home.recruitment_text_14") }}</div>
                  <div>{{ $t("home.recruitment_text_15") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleImages from "@/components/titleImages";
import IMGS from "@/assets/images/人员招聘.jpg";
import swiper1 from "@/assets/images/join_us1.jpg";
import swiper2 from "@/assets/images/join_us2.jpg";
import swiper3 from "@/assets/images/join_us3.jpg";
import LeftBox from "@/components/LeftNav";
import { getStaffRecruitInfo } from "@/api/staffRecruit";
import { splitArray } from "@/common/splitArray";
import moment from "moment";

export default {
  name: "Home",
  components: { titleImages, LeftBox },
  data() {
    return {
      keyWord: "",
      pages: {
        total: 0,
        page: 0,
        pageSize: 10,
      },
      staffDataList: [
        {
          url: swiper1,
          name: "Bru Bee",
        },
        {
          url: swiper2,
          name: "Bru Bee",
        },
        {
          url: swiper3,
          name: "Bru Bee",
        },
      ],
      addressIndex: "",
      addressList: [
        {
          id: "",
          text: this.$t("home.full"),
        },
        {
          id: "1",
          text: this.$t("home.shenzhen"),
        },
        {
          id: "2",
          text: this.$t("home.guangzhou"),
        },
        {
          id: "3",
          text: this.$t("home.shanghai"),
        },
        {
          id: "4",
          text: this.$t("home.guangxi"),
        },
        {
          id: "5",
          text: this.$t("home.sichuan"),
        },
      ],
      releaseTimeIndex: "",
      releaseTimeList: [
        {
          id: "",
          text: this.$t("home.full"),
        },
        {
          id: "1",
          text: this.$t("home.three_days"),
        },
        {
          id: "2",
          text: this.$t("home.one_week"),
        },
        {
          id: "3",
          text: this.$t("home.in_one_month"),
        },
        {
          id: "4",
          text: this.$t("home.in_three_months"),
        },
        {
          id: "5",
          text: this.$t("home.within_half_a_year"),
        },
      ],
      myImageObj: {
        url: IMGS,
        width: "100%",
        height: "100%",
      },
      navList: [
        {
          link: "/",
          biaoti: this.$t("home.social_recruit"),
          g_id: 1,
        },
        {
          link: "/",
          biaoti: this.$t("home.campus_recruiting"),
          g_id: 2,
        },
        {
          link: "/",
          biaoti: this.$t("home.group_recruit_statement"),
          g_id: 3,
        },
      ],
      columns: [
        { prop: "zhiwei", label: "职位名称" },
        { prop: "zhiweileixin", label: "职位类别" },
        { prop: "didian", label: "工作地点" },
        { prop: "create_date", label: "发布时间" },
      ],
      tableData: [],
      inviveList: [
        {
          companyName: "深圳米仓供应链集团有限公司",
          position: "自身ODOO开发工程师",
          address: "深圳",
          ageLimit: "5-10年",
          education: "本科",
          wage: "面议",
          inviteNum: "招聘1人",
          updateTime: "2021-06-24更新",
        },
        {
          companyName: "四川中发工品有限公司",
          position: "产品经理",
          address: "四川",
          ageLimit: "5-10年",
          education: "本科",
          wage: "面议",
          inviteNum: "招聘1人",
          updateTime: "2021-06-24更新",
        },
        {
          companyName: "广州市米仓供应链",
          position: "会计",
          address: "广州",
          ageLimit: "5-10年",
          education: "本科",
          wage: "面议",
          inviteNum: "招聘1人",
          updateTime: "2021-06-24更新",
        },
        {
          companyName: "广西米仓供应链",
          position: "技术部总监",
          address: "上海",
          ageLimit: "5-10年",
          education: "本科",
          wage: "面议",
          inviteNum: "招聘1人",
          updateTime: "2021-06-24更新",
        },
        {
          companyName: "深圳米仓供应链集团有限公司",
          position: "前台行政人员",
          address: "深圳",
          ageLimit: "1-2",
          education: "专科以上",
          wage: "面议",
          inviteNum: "招聘1人",
          updateTime: "2021-06-24更新",
        },
        {
          companyName: "广州市米仓供应链",
          position: "会计",
          address: "广州",
          ageLimit: "5-10年",
          education: "本科",
          wage: "面议",
          inviteNum: "招聘1人",
          updateTime: "2021-06-24更新",
        },
      ],
      isPlatTplePc: true,
      tabIndex: 1,
      initTableList: [],
      obj: [],
    };
  },
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    locale() {
      return localStorage.getItem("language") || "zh";
    },
  },
  filters: {
    createformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  methods: {
    getList() {
      const data = {
        sort: "sort,desc",
      };
      getStaffRecruitInfo(data).then((res) => {
        const { content, totalElements } = res;
        this.pages.total = totalElements;
        let list;
        switch (this.locale) {
          case "en":
            list = content.map((item) => {
              if (item.infoEn) {
                return JSON.parse(item.infoEn);
              } else {
                return {};
              }
            });
            break;
          case "zh":
            list = content;
            break;
          default:
            list = content;
            break;
        }
        this.tableData = splitArray(list, 8);
        console.log("this.tableData===", this.tableData);
        this.initTableList = JSON.parse(
          JSON.stringify(splitArray(this.obj, 8))
        );
      });
    },
    tabBtn(item) {
      this.tabIndex = item.item.g_id;
      console.log(this.tabIndex);
    },
    godetil(scope) {
      window.open(scope.jobUrl);
    },
    currentChange(page) {
      this.pages.page = page - 1;
    },
    myClick(row) {
      console.log(row);
      this.$router.push({
        name: "staffRecruitDetile",
        params: {
          row: row,
        },
      });
    },
    filterList(text) {
      if (text == "") {
        console.log(6666);
        this.tableData = JSON.parse(JSON.stringify(this.initTableList));
        return;
      }
      let newArr = this.tableData[0].filter((item) => {
        console.log(item);
        // return isNaN(text)
        //   ? item.zhiwei.indexOf(text.trim()) != -1
        //   : item.didian
        //     ? item.didian.indexOf(text.trim()) != -1
        //     : false;
        return (
          item.zhiwei.indexOf(text.trim()) != -1 ||
          item.didian.indexOf(text.trim()) != -1 ||
          item.xueli.indexOf(text.trim()) != -1 ||
          false
        );
      });
      this.tableData[0] = newArr;
    },
    searchList(text) {
      this.filterList(text);
      // if (text == "") {
      //   console.log(6666);
      //   this.tableData = JSON.parse(JSON.stringify(this.initTableList));
      //   return;
      // }
      // let newArr = this.tableData[0].filter(item => {
      //   console.log(item);
      //   // return isNaN(text)
      //   //   ? item.zhiwei.indexOf(text.trim()) != -1
      //   //   : item.didian
      //   //     ? item.didian.indexOf(text.trim()) != -1
      //   //     : false;
      //   return (
      //     item.zhiwei.indexOf(text.trim()) != -1 ||
      //     item.didian.indexOf(text.trim()) != -1 ||
      //     false
      //   );
      // });
      // this.tableData[0] = newArr;
    },
    changeAddress(item) {
      this.keyWord = "";
      this.releaseTimeIndex = "";
      this.addressIndex = item.id;
      if (!item.id) {
        this.filterList("");
      } else {
        let text = item.text;
        let newArr = JSON.parse(JSON.stringify(this.initTableList))[0].filter(
          (item) => {
            console.log(item);
            return (
              item.zhiwei.indexOf(text.trim()) != -1 ||
              item.didian.indexOf(text.trim()) != -1 ||
              false
            );
          }
        );
        this.tableData[0] = newArr;
      }
    },
    changeRelease(item) {
      this.addressIndex = "";
      this.releaseTimeIndex = item.id;
      if (!item.id) {
        this.filterList("");
        return;
      }
      let nowDate = new Date();
      let aimDate = null;

      if (item.id == 1) {
        aimDate = new Date(nowDate.setDate(nowDate.getDate() - 3));
      } else if (item.id == 2) {
        aimDate = new Date(nowDate.setDate(nowDate.getDate() - 7));
      } else if (item.id == 3) {
        aimDate = new Date(nowDate.setDate(nowDate.getDate() - 30));
      } else if (item.id == 4) {
        aimDate = new Date(nowDate.setDate(nowDate.getDate() - 90));
      } else if (item.id == 5) {
        aimDate = new Date(nowDate.setDate(nowDate.getDate() - 180));
      }
      console.log(aimDate);
      let month = aimDate.getMonth() + 1;
      month = month > 10 ? month : "0" + month;
      let date = aimDate.getFullYear() + "" + month + "" + aimDate.getDate();
      console.log(date);
      let newArr = JSON.parse(JSON.stringify(this.initTableList))[0].filter(
        (item) => {
          let time = item.fabushijian.replace(/-/g, "");
          return aimDate > time;
        }
      );
      this.tableData[0] = newArr;

      // }
    },
    goPagePre() {
      this.tabIndex = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
.recruit_sm {
  padding-bottom: 50px;
  border: 1px solid #eee;
}
.staff_top {
  width: 100%;
  height: 365px;
  display: flex;
  margin: 30px 0 16px 0;
  position: relative;

  .staff_swiper {
    width: 493px;
    height: 325px;
    position: absolute;
    top: 20px;
    left: 0;
  }
  img {
    width: 600px;
    height: auto;
  }
  .company_info {
    &::-webkit-scrollbar {
      display: none;
    }
    // flex: 1;
    width: 827px;
    height: 365px;
    // overflow-x:hidden ;
    overflow-y: auto;
    position: absolute;
    right: 0;
    padding: 60px 74px 60px 122px;
    background: url("../../../assets/images/staff_header_bg.png") center
      no-repeat;
    background-size: 100%;
    cursor: pointer;
    &:hover {
      background: url("../../../assets/images/staff_header_bg_hover.png") center;
      .item_name,
      .company_des,
      .show_more {
        color: #fff;
      }
    }
    .item_name {
      font-size: 24px;
      margin-bottom: 18px;
      color: #373737;
      font-weight: 600;
      text-align: right;
    }
    .company_des {
      // padding-right: 180px;
      line-height: 24px;
      font-size: 15px;
      color: #373737;
    }
    .show_more {
      text-align: right;
      font-size: 16px;
      margin-top: 16px;
      color: rgb(9, 9, 145);
    }
  }
}
.right_box {
  .invite_items_ul {
    padding: 16px;
    border: 1px solid #eee;
    .search_title {
      display: flex;
      align-items: center;
      color: #515151;
      font-size: 16px;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #333;
        margin-right: 6px;
        border-radius: 6px;
        border: 1px solid #ccc;
      }
    }
    .search_div {
      width: 100%;
      padding: 0 177px;
      margin: 30px auto;
      .filter_div {
        .filter_address {
          display: flex;
          padding-top: 30px;
          span {
            color: #525252;
            font-size: 15px;
          }
          ul {
            display: flex;
            li {
              font-size: 15px;
              color: #6b6a69;
              margin-left: 20px;
              cursor: pointer;
            }
            .activeColor {
              color: #e88b11;
            }
          }
        }
      }
    }
    .items_li {
      display: flex;
      padding: 30px 40px;
      background: #f8f8f8;
      align-items: center;
      margin-bottom: 8px;
      .invite_company {
        width: 240px;
        color: #666;
        font-size: 14px;
      }
      .invite_position {
        flex: 1;
        .positon_name {
          font-size: 19px;
          color: #333;
          font-size: 500;
        }
        .positon_ask {
          line-height: 24px;
          font-size: 13px;
          color: #999;
          span {
            font-size: 13px;
            color: #999;
            padding: 0 6px;
            position: relative;
            &::after {
              content: " ";
              width: 1px;
              height: 12px;
              background: #999;
              position: absolute;
              top: 3px;
              right: 0;
              border-radius: 0.1875rem;
            }
          }
          .span_first {
            padding-left: 0;
          }
          .span_last {
            &::after {
              content: " ";
              width: 0;
              height: 0;
              background: #fff;
              position: absolute;
              top: 3px;
              right: 0;
              border-radius: 0.1875rem;
            }
          }
        }
      }
      .invite_num {
        width: 120px;
        color: #666;
        display: flex;
        flex-wrap: wrap;
      }
      .invite_update_time {
        width: 160px;
        color: #666;
      }
      .apply_positon {
        width: 100px;
        color: #d90000;
        cursor: pointer;
      }
    }
  }
}

.page {
  display: flex;
  justify-content: center;
  padding: 40px 0;
  margin: 20px 0;
  padding: 8px 0;
  border: 1px solid #eee;
}
::v-deep.el-table tr {
  cursor: pointer;
}
.btn {
  color: #d90000;
}
.first_footer {
  width: 100%;
}
.zp_sm {
  width: 788px;
  margin: 0 auto;
  .recruit_title {
    color: #111;
    font-size: 27px;
    text-align: center;
    margin: 40px 0 24px 0;
  }
  .sm_contant_div {
    padding-left: 70px;
  }
  .recruit_des {
    color: #111;
    font-size: 16px;
    margin-bottom: 24px;
  }
  ul {
    li {
      color: #111;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      font-family: "微软雅黑" !important;
    }
  }
  .sm_bottom {
    text-align: right;
    margin-top: 30px;
    padding-right: 80px;
    div {
      color: #111;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
  }
}
.go_pre_page {
  position: fixed;
  bottom: 80px;
  right: 60px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
  }
}
::v-deep.el-carousel__arrow {
  i {
    color: #fff;
  }
}
</style>

<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-06-29 17:49:39
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\components\titleImages.vue
-->
<template>
  <div class="images_box_banner">
    <img :src="imgObj.url"
      :width="imgObj.width"
      :height="imgObj.height" />
  </div>
</template>

      

<script>
export default {
  props: {
    imgObj: {
      type: Object,
      required: true
    }
  },
  computed: {
    imgUrl() {
      return this.imagesUrl;
    }
  }
};
</script>

<style lang="scss" scoped>
.images_box_banner {
  width: 100%;
  height: 100%;
}
.images_box {
  width: 100%;
  height: 100%;
  img {
    width: 250px;
    height: 140px;
  }
}
</style>
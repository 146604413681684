<template>
  <div class="inner_container">
    <div class="container">
      <div v-if="isPlatTplePc">
        <title-images :imgObj="myImageObj" />
      </div>
      <div class="inner_content">
        <content-tltle />
        <div class="text_box">
          <left-box :list="goodchainsHexingyewu"
            :listTitle="'核心业务'"
            @tabBtn="tabBtn" />
          <div class="right_box"
            v-html="content">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleImages from "@/components/titleImages";
import contentTltle from "@/components/contentTitle";
import LeftBox from "@/components/LeftNav";
export default {
  name: "Home",
  components: { titleImages, contentTltle, LeftBox },
  data() {
    return {
      goodchainsHexingyewu: [],
      content: "",
      isPlatTplePc: true
    };
  },
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    let { goodchains_hexingyewu } = window;
    this.goodchainsHexingyewu = goodchains_hexingyewu;
    this.content = this.goodchainsHexingyewu[0].neirong;
  },
  methods: {
    tabBtn(obj) {
      this.content = obj.item.neirong;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
.title-box {
  font-size: 24px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: bold;
  color: #333333;
  padding-bottom: 24px;
}
</style>

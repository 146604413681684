import { Service2 } from "@/api";

export function getDispatchInfo(data) {
    return Service2({
        url: 'orderStatus/listByOrderNo/' + data,
        method: "post"
    })
}
//获取预约单timeline
export const getReservationInfo = (keyWord) => {
    return Service2({
        url: `gwms/confirmationOrderStatus/findByNo/${keyWord}`,
        method: 'post'
    })
}
export default { getDispatchInfo }
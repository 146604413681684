<template>
  <div class="inner_container">
    <div class="container">
      <title-images :imgObj="myImageObj" />
      <div class="inner_content">
        <!-- <content-tltle /> -->
        <!-- <content-tltle />
        <div>{{ detilContent }}</div> -->
        <div class="text_box">
          <left-box
            :list="navList"
            :listTitle="'人员招聘'"
          />
          <div class="right_box">
            <div class="title">{{ detilContent.zhiweileixin }}</div>
            <div class="right_content">
              <div class="right_box">
                <div class="right_txt">
                  工作地点: <span>{{ detilContent.didian }}</span>
                </div>
                <div class="right_txt">
                  职位类型: <span>{{ detilContent.zhiwei }}</span>
                </div>
              </div>
              <div class="right_box">
                <div class="right_txt">工作经验: 3-5年</div>
                <div class="right_txt">
                  发布时间: <span>{{ detilContent.create_date }}</span>
                </div>
              </div>
            </div>
            <div class="ul-box">
              <div v-html="detilContent.zhiweimiaoshu"></div>
              <!-- <div>职位描述</div>
              <ul>
                <li>1、根据产品需求评审和完成前端系统架构设计</li>
                <li>
                  2、与产品，UI合作快速理解需求，能够对产品提出改进及优化建议，并按照公司规范将需求落实到开发工作
                </li>
                <li>
                  3、在理解前端开发流程的基础上，结合前端实际建立或优化提升工作效率的工具
                </li>
                <li>
                  4、紧跟业界前沿技术，并能够快速将前沿技术应用到产品，不断提升用户交互体验，页面性能，以及系统可维护性，驱动业务的发展
                </li>
              </ul> -->
            </div>
            <div class="ul-box">
              <div v-html="detilContent.renzhiyaoqiu"></div>
              <!-- <div>任职要求</div>
              <ul>
                <li>
                  1、计算机及相关专业本科以上学历，毕业于著名高校优先考虑，可以阅读英文文档
                </li>
                <li>
                  2、热爱前端开发技术，具备4年以上Web前端工作经验，熟练掌握HTML
                  / CSS / JavaScript相关技能，熟悉Node.js
                </li>
                <li>
                  3、熟悉React/Angular前端框架，深入理解React/Angular运作机制，具备1年以上React/Angular框架的开发经验
                </li>
                <li>
                  4、具有前端工程、模块化、组件化经验，熟练使用 webpack
                  /grunt/gulp等一种以上的构建工具
                </li>
                <li>
                  5、了解spring等后端开发技术或熟悉BI业务，有大数据开发经验者优先
                </li>
                <li>
                  6、具备良好的沟通能力，具有较强学习能力，能独立思考能力并积极主动，责任感强
                </li>
                <li>
                  7、有创新精神、喜欢接受挑战，特别对于大数据行业未来的新趋势、产品或商业有好奇心
                </li>
              </ul> -->
            </div>

            <div class="button">
              <button @click="btn(detilContent.toudilianjie)">立即申请</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleImages from "@/components/titleImages";
import LeftBox from "@/components/LeftNav";
import IMGS from "@/assets/images/人员招聘.jpg";//05-人员招聘
// import contentTltle from "@/components/contentTitle";
export default {
  name: "Home",
  components: { titleImages, LeftBox },
  data() {
    return {
      myImageObj: {
        url: IMGS,
        width: "100%",
        height: "100%"
      },
      navList: [
        {
          link: "/",
          biaoti: "人员招聘"
        }
      ]
    };
  },
  computed: {
    detilContent() {
      return this.$route.params.row;
    }
  },
  methods: {
    btn(toudilianjie) {
      window.open(toudilianjie);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/sass/inner.scss";
.right_content {
  margin-top: 17px;
  width: 910px;
  height: 80px;
  background: #fafafa;
  display: flex;
  .right_box {
    .right_txt {
      font-weight: 400;
      color: #333333;
      margin: 18px 0;
    }
  }
}
.ul-box {
  margin-top: 40px;
  ul {
    margin-top: 20px;
    li {
      margin: 10px 0;
    }
  }
}
.button {
  margin-top: 40px;
  button {
    width: 92px;
    height: 32px;
    background: #d31223;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #d31223;
    color: #ffffff;
  }
}
</style>

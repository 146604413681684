<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-06-29 18:27:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\components\LeftNav.vue
-->
<template>
  <div class="left_box">
    <p class="business_serve_title">{{ $t("home.manpower_needs") }}</p>
    <ul>
      <!-- <li class="left_title">{{ listTitle }}</li> -->
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="index == active ? 'li_active' : ''"
        @click="tabBtn(item, index)"
      >
        <span></span>
        <a href="javascript:;">{{
          item.biaoti == "" ? $t("home.no_title") : item.biaoti
        }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    listTitle: {
      type: String,
      required: false,
    },
  },
  methods: {
    tabBtn(item, index) {
      let obj = { item: item, index: index };
      this.$emit("tabBtn", obj);
      this.active = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.left_box {
  margin-right: 24px;
  .business_serve_title {
    font-size: 24px;
    padding-bottom: 14px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
  }
  ul {
    .left_title {
      background: #d31223;
      color: #fff;
      font-size: 16px;
      padding: 0 25px;
    }
    li {
      width: 100%;
      min-height: 42px;
      padding: 10px 0;
      // line-height: 42px;
      border-bottom: 1px solid #f0f0f0;
      overflow: hidden;
      padding: 0 25px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #333;
        margin-right: 6px;
        border: 1px solid #ccc;
      }
      a {
        font-size: 14px;
        font-family: HarmonyOS_Sans_SC;
        font-weight: 400;
        color: #333;
      }
    }
    .li_active {
      background-color: #d31223;
      color: #fff;
      span {
        background-color: #fff;
      }
      a {
        color: #fff;
      }
    }
  }
}
@media (max-width: 768px) {
  .left_box {
    padding: 10px;
    ul {
      width: 100%;
      border: none;

      li {
        width: 100%;
        height: 42px;
        text-align: center;
        line-height: 42px;
        border: 2px solid #f0f0f0;
      }
      .li_active {
        color: #d31223;
      }
    }
  }
}
</style>
<!--
 * @Author: your name
 * @Date: 2021-07-01 09:17:27
 * @LastEditTime: 2021-07-09 16:45:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\Home\index.vue
-->
/** * Home * * @author hezhendong 20210316 */
<template class="home_index">
  <div class="home_index">
    <my-header></my-header>
    <div style="width:100%;height:80px"></div>
    <div
      :class="this.$route.path == '/concactUs' ? 'us_container' : 'containner'"
    >
      <router-view />
    </div>
    <my-bottom @openDrawer="openDrawer" />
    <el-drawer
      :title="$t('home.legal_statement')"
      :visible.sync="isShowDrawer"
      :with-header="false"
      size="345"
    >
      <div class="right_drawer">
        <div class="drawer_content">
          <div class="drawer_item" v-if="openType == 1">
            <p class="row_p">{{$t('home.legal_statement')}}</p>
            <div class="item_des">
              {{$t('home.legal_statement_1')}}
            </div>
            <p class="row_p_title">{{$t('home.copyright_notice')}}</p>
            <div class="item_des">
              {{$t('home.copyright_notice_1')}}
            </div>
            <p class="row_p_title">{{$t('home.disclaimer')}}</p>
            <div class="item_des">
              {{$t('home.disclaimer_1')}}
            </div>
          </div>
          <div class="drawer_item" v-if="openType == 2">
            <p class="row_p">{{$t('home.website_map')}}</p>
            <p class="row_p_title">{{$t('home.draw_about')}}</p>
            <div class="item_des">
              {{$t('home.enterprise_profile')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{$t('home.branch')}}
            </div>
            <p class="row_p_title">{{$t('home.draw_business_service')}}</p>
            <div class="item_des">
              {{$t('home.rice_industrial_platform')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{$t('home.rice_popcorn_platform')}}
            </div>
            <div class="item_des">
              {{$t('home.rice_outside_platform')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{$t('home.rice_oversea_store')}}
            </div>
            <p class="row_p_title">{{$t('home.join_us')}}</p>
            <div class="item_des">
              {{$t('home.social_recruitment')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{$t('home.micang_recruitment_statement')}}
            </div>
            <p class="row_p_title">{{$t('home.contact_us')}}</p>
            <div class="item_des">
              {{$t('home.address')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{$t('home.phone')}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$t('home.mail')}}
            </div>
          </div>
        </div>
        <div class="cancel_div">
          <div @click="cancelBtn" class="cancel_btn">{{$t('home.back')}}</div>
        </div>
      </div>
    </el-drawer>
    <div class="back_top" @click="backTopClick" v-show="isShowBack">
      <img src="../../assets/images/back_top.png" alt="" />
    </div>
    <div
      @click="goPagePre"
      class="go_pre_page"
      v-if="
        this.$route.path == '/newCenterDetil' ||
          this.$route.path == '/staffRecruitDetile'
      "
    >
      <img src="../../assets/images/go_pre.png" alt="" />
    </div>
  </div>
</template>

<script>
import MyHeader from "@/components/header";
import myBottom from "@/components/bottom";
export default {
  name: "Home",
  components: {
    MyHeader,
    myBottom
  },
  data() {
    return {
      isPlatTplePc: true,
      isShowDrawer: false,
      openType: null,
      isShowBack: false
    };
  },
  created() {
    this.activeIndex = this.$route.path;

    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    openDrawer(type) {
      this.openType = type;
      this.isShowDrawer = true;
    },
    cancelBtn() {
      this.isShowDrawer = false;
    },
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isShowBack = scrollTop > 200 ? true : false;
    },
    backTopClick() {
      window.scroll(0, 0);
    },
    goPagePre() {
      if (this.$route.path == "/newCenterDetil") {
        console.log(this.$route.query.linkPage);
        if (this.$route.query.linkPage == "/index") {
          this.$router.push("/index");
        } else {
          this.$router.push("/newCenter");
        }
      } else {
        this.$router.push("/staffRecruit");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar{
  display: none;
}
.us_container {
  background: #f0f2f5;
}
.right_drawer {
  display: flex;
  flex-direction: column;
  width: 349px;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  // z-index: 199;
  overflow: hidden; 
  padding: 61px 30px 0 30px;
}
.drawer_item {
  height: calc(100vh - 140px);
  overflow-y: auto;
  margin-bottom: 20px;
  .row_p {
    font-size: 20px;
    color: #04e5d8;
    margin-bottom: 31px;
  }
  .row_p_title {
    font-size: 17px;
    color: #04e5d8;
    margin-top: 52px;
    margin-bottom: 15px;
  }
  .item_des {
    font-size: 12px;
    color: #ffffff;
    line-height: 22px;
  }
}
.open_active {
  right: 0 !important;
}
.drawer_content {
  flex: 1;
}
.cancel_div {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.cancel_btn {
  width: 80px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background: #04e5d8;
  border-radius: 13px;
  color: #fff;
  margin: 0 30px 30px 0;
}
::v-deep.el-row {
  height: 100%;
}
::v-deep.el-col {
  height: 100%;
}
::v-deep.el-carousel--horizontal {
  height: 100%;
}
::v-deep.el-carousel__container {
  height: 100%;
}
::v-deep.el-drawer {
  background: rgba(255, 255, 255, 0.3) !important;
}
.home_index {
  height: 100%;
}
// .index_container {
//   height: 100%; //calc(100% - 80px);
//   overflow-y: hidden;
// }
.containner {
  // height: 100%;
}
.back_top {
  position: fixed;
  bottom: 136px;
  right: 60px;
  cursor: pointer;
  img {
    width: 50px;
    height: auto;
  }
}
.go_pre_page {
  position: fixed;
  bottom: 80px;
  right: 60px;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 768px) {
  .index_container {
    // height: 100%; // calc(100% - 44px);
    // overflow-y: hidden;
  }
}
</style>
